body.auth {
  padding-top: 25px;
  background-color: #f7f7f7;
}
.authContainerLeft {
  @media(max-width: 1024px) {
    margin-top: 3%;
    text-align: center;
    h2 {
      font-family: 'Lato', sans-serif;
      font-weight:900;
      font-size:30px;
    }
    h3 {
      font-family: 'Lato', sans-serif;
      font-weight:400;
      margin-top: 1%;
      font-size:14px;
    }
  }
  @media(min-width: 1024px) {
    text-align: left;
    margin-top:10%;
    &.ordersWorkspaceLeft {
      margin-top:4%;
      ul.bullets-onboarding {
        li{
          display:block;
          margin: 3% 0;
          &:before {
            display: block;
            float: left;
            content: " ";
            width: 30px;
            height: 30px;
            background: url(../img/btn-deselect.svg) no-repeat 50% 5%;
          }
        }
      }
    }
    h2 {
      font-family: 'Lato', sans-serif;
      font-weight:900;
      font-size:44px;
    }
    h3 {
      font-family: 'Lato', sans-serif;
      font-weight:400;
      margin-top:5%;
      line-height:35px;
    }
  }
}
.authContainer {
  margin: 32px auto 16px auto;
  padding: 1.5em 3em;
  background: #ffffff;
  zoom: 1;
  max-width: 500px;
  text-align: center;
  border-radius: 24px;
  h2 {
    font-family: 'Lato', sans-serif;
    font-weight:900;
  }
  .auth-logo {
    margin: 0 auto;
  }
  .password_recovery_img {
    max-width: 150px;
    height:auto;
    margin-bottom:15px;
  }
  @media (max-width: 768px) {
    padding: 1.5em 1em;
  }
}

.authContainer-section {
  margin-top: 25px;
  font-size:0.8em;

  &.first {
    margin-top: 0;
  }
}

.providers {
  display: flex;
  align-content: center;
  justify-content: center;

  > li {
    padding: 0;
  }
  @media (max-width: 768px) {
    flex-shrink: 0;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}

.provider {
  display: flex;
  margin: 3px 5px;
  width: 130px;
  height: auto;
  min-height: 40px;
  padding: 0.3em;
  text-decoration: none;
  border:2px solid #E9E0E7;
  border-radius:12px;

  &:hover, &:active, &:focus {
    opacity:0.7;
    text-decoration: none;
  }
}

.provider-image {
  line-height: 0;
}

.provider-text {
	@include font-weight-normal;
	flex-grow: 1;
	padding: 6px 0;
  font-size:16px;
	line-height: 18px;
	text-align: center;
	color: #222626;
}

.provider-imageGoogle {
	padding: 4px 6px;
  img {
    width:20px;
    height:20px;
  }
}

.provider-imageTwitter {
	padding: 6px;
  img {
    width:20px;
    height:20px;
  }
}

.authFormHelp {
	margin-top: 24px;
}

.field-icon {
  cursor: pointer;
  float: right;
  margin-right: 15px;
  position: relative;
  z-index: 2;
  color: $Grey700;
  &.password {
    margin-top: -36px;
  }
  &.password-confirm {
    margin-top: -60px;
  }

  &:hover {
    opacity:0.7;
  }
}

.passwordStrength {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  visibility:hidden;
  height:0;
  opacity:0;

  &.shown {
    visibility:visible;
    height:50px;
    opacity:1;
  }
}

.passwordStrength-meter {
  display: flex;
  margin-bottom: 4px;
}

.passwordStrength-meterBoxes {
  display: flex;
  flex-grow: 1;
  margin-right: 16px;
}

.passwordStrength-meterBox {
  flex-grow: 1;
  height: 18px;
  margin: 2px 5px;
  background-color: light($gray);
  border-radius:24px;
}

.passwordStrength-meterText {
  width: 200px;
  text-align: right;
  font-size: $font-size-tiny;
}

.passwordStrength-helpText {
  font-size: $font-size-tiny;
}

input[type="radio"],
input[type="checkbox"] {
  width:20px;
  height: 20px;
  float: left;
}

.authFormSignup-invalidEmail {
  text-align: left;
}

.verify_img {
  max-width: 120px;
  height:auto;
  margin-bottom:20px;
}

.form-group{
  & label p.terms{
    margin-left: 30px;
  }
}
