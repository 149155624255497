.flatpickr-wrapper {
    width: 100%
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
    content: none;
}

.flatpickr-calendar {
    border-radius: 0px;
    box-shadow: none;
    border: none;
}

.flatpickr-months {
    border-top: 1px solid light($gray);
    border-left: 1px solid light($gray);
    border-right: 1px solid light($gray);
}

.flatpickr-month {
    height: 35px !important;
}

.flatpickr-innerContainer, .flatpickr-time {
    border-left: 1px solid light($gray);
    border-right: 1px solid light($gray);
}

.datetime-input-placeholder {
    color: $gray;
}

.flatpickr-current-month input.cur-year {
    display:none;
}