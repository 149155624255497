// Layout


.col2 {
  column-count: 2;
  column-gap: 0;
  li {
    page-break-inside: avoid;
  }
}

.col3 {
  column-count: 3;
  column-gap: 0;
  li {
    page-break-inside: avoid;
  }
}

.col2-laptop {
  display: flex;
  margin-right: -20px;

  > * {
    margin-right: 20px;
    flex-grow: 1;
  }

  @include respond-to(0, tablet) {
    flex-direction: column;
    margin-right: 0;
    > * {
      margin-right: 0;
    }
  }
}

.break-50 { max-width: 50%; }
.break-60 { max-width: 60%; }
.break-70 { max-width: 70%; }
.break-80 { max-width: 80%; }
.break-90 { max-width: 90%; }

.break-word {
  overflow-wrap: break-word;
}

.total {
  font-family: $font-family-sans-serif;
}

.inprogress {
  display: inline-block;
  opacity: 0.5;
}
.completed {
  display: inline-block;
  opacity: 1;
}


.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}

.heading-link {
  @include no-underline;
  font-family: $font-family-sans-serif;
  font-size: $font-size-small;
  @include font-weight-bold;
  padding-top: 10px;
  float: right;
}

// Text
//
// TODO - Please add documentation here...
//
// Styleguide text

.text-left { text-align: left; }
.text-right { text-align: right; }
.text-center { text-align: center; }

 //Video Thumbnails
 //
 //<div class="thumbWrapper">
 //  <img src="http://via.placeholder.com/800x600" alt="Placeholder Image">
 //  <div class="thumbWrapper-duration">7:04</div>
 //</div>
 //
 //Styleguide thumbWrapper

.thumbWrapper {
  position: relative;
  display: flex;
  align-items: center;
  height: 0;
  padding-bottom: 56.25%;
  border: 1px solid light($gray);
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.thumbWrapper-duration {
  position: absolute;
  top: 4px;
  right: 4px;
  background: black;
  padding: 1px 3px;
  font-size: 11px;
  color: #fff;
  @include font-weight-bold;
}

@media(max-width: 767px) {
  .break-50, 
  .break-60,
  .break-70,
  .break-80,
  .break-90 { max-width: 100%; }
}
