.userSidebar {
    padding:10px;
}

.userSidebar-sendMessage {
    display: flex;
    justify-content: center;
}

.userSidebar-header {
    border-bottom:1px solid;
    margin-bottom: 12px;
    padding-left: 0px;
    padding-right: 0px;
}

.userSidebar-link {
    float: right;
}

.userProfile-navbar {
    display: flex;
    align-items: center;
    margin: 0;
    flex-grow: 1;
    font-size: $font-size-h2;
    color: $teal;
    font-family: 'DM Sans Bold', Lato, "Lucida Grande", Tahoma, Sans-Serif;
}

.userSidebar-section {
    line-height: 1.5em;
    margin-bottom: 1em;
}

.userAccount-section {
    margin: 20px;
}

.userAccount-sectionHeader {
    padding-bottom: 16px
}

.join {
    font-style: italic;
    color: #999;
}

.userSidebar-aboutSubsection {
    margin-bottom: 1em;
    padding-left: 0px;
    padding-right: 0px;
}

.userSidebar-picture {
    justify-content: center;

}

.userAccount-sectionSeparator {
    border-top: 1px solid #888;
}