// css for team banners
.banner-container {
  margin-top: 20px;
  max-height: 235px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img.banner-image {  
    flex-shrink: 0; 
  }
}
