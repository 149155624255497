.downloadSubtitles-example {
  display: none;
}

.downloadSubtitles-exampleFiles {
  display: block;
  overflow-y: auto;
  max-height: 200px;
  border: 1px solid $gray;
  padding: 4px 8px;

  > tr > td {
    padding-right: 8px;
  }
}
