table.rules {
    border: 1px solid dark($gray);
    width: 100%;

    a.edit {
        float: right;
        font-size: $font-size-normal;
    }

    > tbody > tr > td {
        padding: 8px;
        border-top: 1px solid light($gray);
        border-left: 1px solid light($gray);

        &:first-of-type {
          border-left: 0;
        }
    }

    > thead > tr > th {
        padding: 8px;
        border: 1px solid dark($gray);
    }

    th {
        table.key-value {
            padding-top: 10px;
            margin: 0;
            @include font-weight-normal;
        }
    }
    tr.rules > td {
        width: 28%;

        &:first-of-type {
            width: 16%;
        }
    }
    h2 {
        padding: 0;
    }
    tr.rules td {
        height: $line-height * 4;
    }
    .rate {
        @include font-weight-bold;
    }
    margin-bottom: 40px;
}

form.rule-table {
    select#id_category-type:disabled {
        color: dark($gray);
        border: 1px solid light($gray);
    }

    .team-selects  {
        @extend .clearfix;
        select {

            float: left;
            width: 30%;
            margin-right: 5%;
            font-size: $font-size-small;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    label.ruleTable-rate input {
        width: 5ch;
        box-sizing: content-box;
        margin-right: 5px;
    }

    .rule-form-errors {
        margin-top: 10px;
        li {
            display: inline;

            &:first-of-type {
                margin-left: 0;
            }
        }
    }
}
