.jobOrder {
  display: flex;
  padding: 32px;
}

.jobOrder-info {
  margin-right: 20px;
  flex-grow: 1;
}

.jobOrder-actions {
  min-width: 300px;
}
