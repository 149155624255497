#styleGuide-toc {
  ul {
    li {
      a {

      }
      ul {
        li {

        }
      }
    }
  }
}
.styleGuide-navLink {
  padding-left: 10px;
  border-left: 4px solid transparent;
  font-size: $font-size-small;
  &.active {
    border-left-color: $teal;
  }
}

.styleGuide-section {
  display: none;
  &.active {
    display: block;
  }

  > ul {
    list-style-type: disc;
    padding-left: 20px;
  }
}

.styleGuide-exampleBox {
  position: relative;
  border: 1px solid light($gray);
}

.styleGuide-exampleTitle {
  position: absolute;
  display: inline-block;
  top: 0;
  right: 0;
  margin: 0;
  padding: 3px 5px;
  background-color: light($gray);
  color: $snow;
  font-size: 12px;
}

.styleGuide-exampleBoxHeader {
  background: $snow;
  margin: 0;
  padding: 10px 20px;
  border-bottom: 1px solid light($gray);
}

.styleGuide-exampleSource {
  padding: 20px;
  padding-top: 30px;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid light($gray);
  border-radius: 0;
  color: $text-color;
}

.styleGuide-examples {
  position: relative;
  padding: 20px;
}

.styleGuide-example {
  padding-bottom: 20px;
}

.styleGuide-exampleDescription {
  @include font-weight-normal;
  font-family: $font-family-sans-serif;
  margin-left: 20px;
}


.styleGuide {
  // Color Palette
  ul.colorGrid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    li {
      display: flex;
      width: 120px;
      height: 120px;
      border-radius: 12px;
      margin: 8px;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: 1px solid light($gray);
    }
  }
  // Typography
  .type-open-sans {
    font-family: $font-family-sans-serif;
  }
  .type-raleway {
    font-family: $headings-font-family;
  }
}

// Icons
ul.styleGuide-iconList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  li {
    display: block;
    width: 20%;
    height: 120px;
    padding: 10px;
    margin: 10px;
    background-color: $snow;
    color: $gray;
    text-align: center;
    i {
      display: block;
      font-size: 30px;
      padding: 10px;
      &:before {
        color: dark($gray) !important;
      }
    }
    span {
      display: block;
      width: 100%;
      clear: both;
    }
    &:hover {
      background-color: $teal;
      color: #fff;
      i::before { color: #fff !important; }
    }
  }
}
