.activityStream-table {
  margin-top: 20px;
  tbody {
    tr {
      td {
        
      }
    }
  }
}

@media all and (max-width: $screen-md-max) {}

@media all and (max-width: $screen-sm-max) {}

@media all and (max-width: $screen-xs-max) {}