.videosCreateSwitcher {
  margin-top: 20px;

  @include respond-to(tablet, 0) {
    display: flex;
  }
}
.videosCreateSwitcher-box {
  padding: 48px 32px 60px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  @include respond-to(desktop) {
    padding: 48px 50px 60px;
  }
}

.videosCreateSwitcher-features {
  padding: 24px 0;
  @include respond-to(phone) {
    display: none;
  }
}

.videosCreateSwitcher-spacer {
  flex-grow: 1;
}

.videosCreateSwitcher-button {
  margin-top: 20px;
  text-align: center;
  padding: 0 20px;

  @include respond-to(laptop) {
    padding: 0 60px;
  }
  @include respond-to(desktop) {
    padding: 0 100px;
  }
}

.videosCreateTabs {
  margin-top: 60px;
  margin-bottom: 100px;
}

.videosCreateTab {
  @include respond-to(laptop, 0) {
    margin: 0 40px;
    max-width: none;
  }
  @include respond-to(desktop) {
    margin: 0 80px;
  }
}

.videosCreateForm {
  padding: 16px;

  @include respond-to(laptop, 0) {
    padding: 16px 100px;
  }
  @include respond-to(desktop) {
    padding: 16px 250px;
  }
}

