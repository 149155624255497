.stepList {
  margin: 5px 0;
  > li {
    display: inline-block;
    width: 30px;
    text-align: center;
    margin-right: 20px;
  }
  i.icon {
    font-size: 25px;
    color: $gray;
  }

  &.tight {
    margin: 0;
    > li {
     margin-right: 0;
    }
  }
}
