.zero-state {
  font-family: Lato, "Lucida Grande", Tahoma, Sans-Serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 20px;
  text-align: center;
  background-color: transparent;
  border: 1px solid $snow;
  margin: 27px 0;
  p {
    font-size: 13px;
  }
  img.zero-state-arrow {
    position: relative;
    height: 100px;
    right: 75px;
    top: -8px;
  }
  img.zero-state-underlined {
    position: relative;
    width: 86px;
    height: 11px;
    top: -7px;
    right: 29px;
  }
}

.search-zero-result {
  background: url(../img/empty_state_0_result.svg) no-repeat 50% 15%;
  min-height: 280px;
  background-size: 17%;
  padding-top: 11%;
  text-align: center;
}

.search-zero-result-modal {
  margin: -23px auto;
  width: 100%;
  z-index: 999;
  background: #FFF url(../img/empty_state_0_result.svg) no-repeat 50% 15%;
  min-height: 310px;
  background-size: 26%;
  padding-top: 17%;
  text-align: center;
}




// @media all and (max-width: $screen-md-max) {}

// @media all and (max-width: $screen-sm-max) {}

// @media all and (max-width: $screen-xs-max) {}
