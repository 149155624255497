.workflowDiagramContainer {
  width:100%; 
  height: 130px; 
  background-color: $snow;
  display: flex;
  margin-bottom: 8px;
}

@include respond-to(0, tablet) {
  .workflowDiagramContainer {
    max-width: 90vw; // prevent spilling over for small widths
  }
}

.workflowDiagramSegment {
  min-width: 150px;
  height: 100%;
  padding-left: 45px;
  background-color: $lime;
  color: $white;
  display: inline-flex;
  flex-direction: column;
  justify-content: center; 

  &.first {
    padding-left: 20px;
  }

  &.last {
    min-width: 150px;
    max-width: 150px;
    padding-left: 30px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: $snow;
    align-items: center;
    color: black;
    flex: 1;
  }
}

.workflowDiagramSegment .segmentLabel {
  font-size: 18px;
  padding-top: 5px;
}

.workflowDiagramSeparator {
  display: inline;
  margin-left: -5px; //hack to close the gap between the segment and separator
}

.workflowDiagramSeparatorSVG {
  position: absolute;
  z-index: $z-index-front;
}

.workflowDiagramSeparatorSVG .triangle {
  fill: $lime;
}


.workflowDiagramSeparatorSVG .gap {
  fill: $white;
}
