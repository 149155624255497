.videoUrls-added-by {
  display: flex;
  flex-wrap: wrap;  
}

.videoUrls-badges-container {
  display: flex;
  flex-wrap: wrap;

  div {
    margin-right: 5px;
    margin-bottom: 5px;
  }  
}