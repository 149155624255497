.markdownBlock {
  ul {
    @extend ul.bullets;
  }

  ol {
    @extend ol.numbers;
  }

  code {
    display: block;
    white-space: pre;
  }
}
