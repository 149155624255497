.userList {
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.userList-user {
  display: table-row;
  width: 100%;
  font-size: $font-size-normal;
  font-weight: 400;

  &.large {
    font-size: $font-size-h3;
  }
  &:hover {
    background: $snow;
  }
}

.userList-avatar,
.userList-name,
.userList-email {
  display: table-cell;
  padding: 5px;
  vertical-align: middle;
  text-align: left;
  a {
    @include no-underline;
  }
  &:first-child {
    width: 40px;
  }
  &:last-child {
    width: 40px;
  }
  i {
    font-size: 22px;
    margin-right: 10px;
  }
}



.userList-email {
  display: none;

  .userList-user:hover & {
    display: table-cell;
  }
}
