@import 'variables';

// 3rd party
@import "~chartist/dist/chartist";
@import "~select2/dist/css/select2";
@import "bootstrap";
@import "fontawesome";
@import "~flatpickr/dist/flatpickr";
@import "~dropzone/dist/dropzone";

@import 'functions';
@import 'mixins';
@import 'utilities';

// Utils
@import 'base/fonts';
@import 'base/font_icons';
@import 'application/utils';

// Layout Elements
@import 'layout/base';
@import 'layout/page_header';
@import 'layout/page_footer';
@import 'layout/utils';

// Navigation Elements
@import "elements/navigation";
@import "elements/consolidate_header";
@import "elements/language_switcher";

// Global
@import 'base/global';
@import 'base/typography';
@import 'base/colors';
@import 'application/global/grid';

@import 'elements/buttons';
@import 'elements/tabs';
@import 'application/global/navs';
@import 'elements/dropdowns';
@import 'elements/lists';
@import 'elements/list_view';

@import 'elements/forms';
@import 'application/global/progressbars';
@import 'application/global/indicators';
@import 'application/global/charts';

// Elements
@import 'elements/add_video_dialog';
@import 'application/elements/avatars';
@import 'application/elements/step_list';
@import 'application/elements/filtered_list';
@import 'elements/video_card';
@import 'elements/select_list';
@import 'elements/accordion';
@import 'application/elements/subtitles';
@import 'application/elements/subtitles_list';
@import 'elements/dialogs';
@import 'application/elements/action_bar';
@import 'elements/filters';
@import 'elements/filter_box';
@import 'application/elements/comments';
@import 'elements/pagination';
@import 'application/elements/clamp';
@import 'elements/switches';
@import 'elements/searchbar';
@import 'application/elements/zero_state';
@import 'elements/select';
@import "elements/alerts";
@import 'elements/tables';
@import 'elements/video-subtitles-list';
@import 'elements/video-urls-list';
@import 'elements/pricing-table';
@import 'application/elements/workflow_diagram';
@import 'application/elements/workflow_settings';
@import 'application/elements/integrations_settings';
@import 'elements/split_button';
@import 'elements/breadcrumb';
@import 'elements/badges';
@import 'elements/banner';
@import 'elements/stats';
@import 'elements/typography';
@import 'elements/content_container';
@import 'application/elements/datetime_picker';
@import 'elements/dashboard';
@import 'elements/collab';
@import 'elements/download_subtitles';
@import 'elements/markdown_block';
@import 'elements/auth';
@import 'elements/user_list';
@import 'elements/onboarding';
@import 'elements/team_workspace';
@import 'elements/multi_step_pane';
@import 'elements/checkout_table';
@import 'elements/tooltip';
@import 'elements/diffing';
@import 'elements/orders';
@import 'elements/cookie_consent';

// Views
@import 'application/views/style_guide';
@import 'application/views/dashboard';
@import 'application/views/video_details';
@import 'application/views/video_subtitles';
@import 'application/views/messaging_center';
@import 'application/views/member_directory';
@import 'application/views/member_profile';
@import 'application/views/user_profile';
@import 'application/views/activity_page';
@import 'views/videos-create';
@import 'views/team-members';
@import 'views/prorequests';
@import 'views/ondemand';
@import 'views/services';
