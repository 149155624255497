.stat-container {
  padding-left: 25px;
  padding-right: 25px;
  width: 130px;
}

.stat-value {
  font-size: 3em;
  margin-bottom: 0px;
}

.team-stat-container {
  padding: 20px;
  border-bottom: 1px solid light($gray);

  &:first-of-type {
    padding-top: 0px;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

.team-stat {
  display: inline-block;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0px;
  }
}

.team-stat-label {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
}

.dashboard-stats-numbers {
  display: inline-block;
  vertical-align: top;
  padding-right: 25px;
  margin-bottom: 30px;
}


.dashboard-stats-chart {
  padding-right: 25px;
  display: inline-block;
}

.dashboard-stats-chart-inner {
  position:relative; // for positioning the tooltip properly
}

.dashboard-stats-chart-title {
  margin-left: 10px;
  margin-bottom: 10px;
}

.statsNumbersHelpText {
  margin-top: 5px;
  margin-left: 20px;
}

