dl {
  margin: 0;
}

dt {
  @include font-weight-bold;
}

dd {
  @include font-weight-normal;
  padding-bottom: 12px;
}

@include respond-to(tablet, 0) {
  dt.inline {
    display: inline-block;
    padding-right: 8px;
    padding-top: 12px;
  }
  dd.inline {
    display: inline;

    &:after {
      content: "\A";
      white-space: pre;
    }
  }
}

ul {
  &.tight {
    margin: 0;
  }
  &.inline {
    // Disable a list of inline text content
    display: inline-block;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;

      &:not(:last-child):after {
        content: ", ";
        white-space: pre;
      }
    }
    strong {
      @include font-weight-light;
      color: $charcoal;
    }
  }
  &.flex {
    // Similar to inline, but better suited for block and inline-block content (for example buttons)
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 12px 0;
    > li {
      margin: 0 4px 8px 0;
    }
  }
  &.bullets {
    padding-left: 20px;
    list-style-type: disc;
    list-style-position: outside;
  }
}

ol {
  &.numbers {
    padding-left: 20px;
    list-style-type: decimal;
    list-style-position: outside;
  }
}

ol, ul {
  &.with-space {
    > li {
      margin-bottom: 8px;
    }
  }
}

.blockButtonList {
  >li {
    margin-bottom: 16px
  }
}
