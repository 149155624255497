.assignmentDeadlineInput {
  width: 70px;
}

.assignmentDeadlineElementDefaultHidden {
  display: none;
}

.defaultHidden {
  display: none;
}

.assignmentDeadlineTotalContainer {
  padding-top: 0px;
  display: flex;
  align-items: center; 
}

.autocreateSettingsContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 1px; //just to avoid margin collapse
  margin-bottom: 20px;
  background: $snow;
  display: none;
}

.createMissingCollaborationsContainer {
  margin-top: 50px;
}