.badge {
  font-size: $font-size-tiny;
  line-height: 20px;
  @include border-radius-12;
  color: $white;
  background: $gray;
  padding: 4px 10px;
  font-size: 13px;

  &.large {
    display: inline-block;
    margin: 4px 0px;
    font-size: $font-size-small;
    padding: 1px 10px;
  }

  @each $name, $color_info in $colorscheme {
    $color: nth($color_info, 1);
    $alternate: nth($color_info, 2);
    &.#{$name} {
      @include palette($color, $alternate);
    }
    &.#{$name}-dark {
      @include palette(dark($color), $alternate);
    }
    &.#{$name}-light {
      @include palette(light($color), $alternate);
    }
  }

  &.white {
    // create an inset border to make the badge visible
    box-shadow: inset 0px 0px 0px 1px $charcoal;
  }
}
