ul.selectList {
  display: flex;
  flex-wrap: wrap;
  font-size: $font-size-small;
  > li {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 8px;
    color: dark($gray);
    &:hover {
      background-color: $snow;

      .context-menu {
        display: block;
      }
    }
  }
  h3 {
    font-family: $font-family-sans-serif;
    @include font-weight-normal;
    font-size: 16px;
    margin: 0;
  }
  h4 {
    font-size: 13px;
    @include font-weight-bold;
    color: $charcoal;
    margin: 0;
  }
  h3, h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .actions {
    position: absolute;
    top: 0;
    right: 0;
    a, button {
      color: light($gray);
      &:hover, &:focus, &:active {
        color: $teal;
      }
    }
    i {
      font-size: 18px;
      margin-left: 3px;
      &.icon-close {
        font-size: 16px;
      }
      &.icon-mail {
        font-size: 22px;
      }
    }
  }
  .selectList-thumb {
    width: 220px;
    float: left;
    margin-right: 20px;
  }
  .context-menu {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    .dropdown-toggle {
      width: 20px;
      height: 20px;
      background-color: $teal;
      display: block;
      text-align: center;
      line-height: 20px;
      .caret {
        margin: 0;
        color: #ffffff;
      }
    }
    >.dropdown-menu {
      width: auto;
      left: auto;
      right: -5px;
      background: #fff;
      border: 1px solid #d9d9d9;
      &:before, &:after {
        content: '';
        position: absolute;
        display: block;
        top: -9px;
        right: 6px;
        left: auto;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #d9d9d9;
      }
      &:before {
        left: auto;
        right: 7px;
      }
      &:after {
        top: -8px;
        border-bottom-color: #fff;
      }
      li {
        a {
          color: #7e7f80;
        }
      }
    }
  }
}

.selectList-actions {
  position: relative;
  font-size: $font-size-small;
  padding: 8px;
  margin-bottom: 8px;
  .checkbox { display: inline-block; }
  a, button {
    margin-left: 15px;
  }
}

@media(max-width: 767px) {
  .selectList-actions {
    .pull-right {
      float: none !important;
      display: block;
    }
  }
}

// TODO - refactor this. Probably can be generalized.

.selectList-members {
  div {
    margin-right: 5px;
  }
  .selectList-memberDetails {
    width: 35em;

    .member-role {
	color: $charcoal;
	@include font-weight-bold;
    }
  }

  .selectList-itemActions {
    i {
      font-size: 22px;
    }
  }
}

.selectList-messages {
  .message {
    margin-bottom: 0;
  }
}
