#subtitles_list {
  .tab-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 2ch;
    .button {

    }
    form.button {
      font-weight: 700;
      border: 2px solid transparent;
      background-color: #fff;
      padding: 10px;
      .switch {
        top: 0;
        margin-top: 3px;
        margin-left: 7px;
        &:hover {
          cursor: pointer;
        }
      }
      &:hover {
        cursor: auto;
        color: $Text;
        border: 2px solid transparent;
        background-color: #fff;
      }
    }
  }
}
.videoSubtitles-progress {
  ol {
    padding: 0;
    margin-bottom: 30px;
    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      flex-wrap: nowrap;
      justify-content: flex-start;
      font-size: 15px;
      padding: 10px 5px 10px 50px;
      counter-increment: my-awesome-counter;
      color: $Grey800;
      .step-label {
        font-size: 1.5em;
        line-height: 1.7em;
        font-weight: 700;
        &:before {
          position: absolute;
          float: left;
          content: counter(my-awesome-counter);
          width: 30px;
          margin-left: -40px;
          margin-top: 5px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          font-size: 20px;
          color: $Grey800;
          font-weight: bold;
          background-color: #fff;
        }
      }
      i {
        display: inline-block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-right: 5px;
        font-size: 24px;
        text-align: center;
      }
      .avatar {
        margin-right: 5px;
        border-radius: 50%;
        img {
          border-radius: 50%;
        }
      }
      &.complete {

      }
      &.pending {

      }
      &.current {
        opacity: 1;
        color: $Text;
        @include border-radius-12;
        .step-label {
          &:before {
            background-color: #fff;
            color: $Text;
            box-shadow: 2px 5px 8px 0 rgba(0, 0, 0, 0.15), -3px 0px 0px 0 rgba(255, 255, 255, 0.3);
          }
        }
      }
      .vertical-separator {
        position: absolute;
        height: 42px;
        border-left: solid 2px $Accent600;
        bottom: -7px;
        left: 24px;
      }
      &:last-child {
        .vertical-separator {
          display: none;
        }
      }
    }
  }

  .subtitleStatus {
    color: $plum;
    background-color: light($gray);
    padding: 10px 5px 10px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 15px;
    @include font-weight-bold;

    i {
      font-size: 30px;
      margin-right: 5px;
    }
  }

  p {

  }

  .header-actions {
    position: relative;
    top: 0;
    right: 0;
    button {
      padding: 0;
    }
  }

  .dropdown {
    .dropdown-menu {
      left: -60px;
    }
  }
  .videoSubtitles-cta {
    .cta {
      width: fit-content;
      font-size: 1.2em;
      i {
        font-size: 25px;
        margin-right: 4px;
      }
    }
  }
}
.videoSubtitles-revisions {
  font-size: $font-size-small;
  color: $plum;
  > li {
    padding: 8px 12px;

    &.private {
      font-style: italic;
    }
    &.current {
      border: 4px solid $Warning100;
      // margin: 0 -10px;
      padding: 8px 12px;
      @include border-radius-12;
    }
  }
}

@media(max-width: 991px) {
  .videoSubtitles-progress {
    ol {
      li {
        font-size: 14px;
      }
    }
  }

}
