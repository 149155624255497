
.onboarding-intro-text {
  margin-bottom: 20px;
  display: inline-block;
}

.onboarding-intro-header {
  margin-bottom: 5px;
  display: inline-block;
}

.onboarding-container {
  display: block;
  margin: 20px auto;
  max-width: 550px;
  padding: 20px 40px;
  background: $white;
  text-align: center;

  .form-actions {
    text-align: center;
  }
}

.onboarding-heading {
  margin-bottom: 20px;
}

.onboarding-subheading {
  color: $gray;
  font-family: $first-level-headings-font-family;
  font-weight: bold;
  text-align: center;
}

.onboarding-mainHeader {
  text-align: center;
  margin-bottom: 20px;
}

.onboarding-stepHeader {
  text-align: center;
  margin-bottom: 20px;
}

.onboarding-authContainer {
  max-width: 700px;
}

.onboarding-authContainer-inner {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.OrderworkspaceContainerTop {
  text-align: center;
  img {
    margin: 0 auto;
  }
  h2 {
    font-family: $first-level-headings-font-family;
    font-weight: 900;
    font-size: 44px;
  }
}
.OrderworkspaceContainer {
  margin: 3% auto 16px auto;
  padding: 1.5em 3em;
  background: #ffffff;
  zoom: 1;
  max-width: 500px;
  text-align: center;
  border-radius: 24px;
  img {
    width: 100%;
    max-width: 300px;
  }
  button {
    margin: 2em auto;
    display: block;
  }
}