// Shared variables
$line-height: 1.2em;
$max-lines: 10;

.clamp-lines {
  position: relative;

  height: $line-height;
  line-height: $line-height;
  overflow: hidden;
  padding: 0 !important;

  p {
    // Make the margin be exactly one line, so we don't end up cutting off between lines
    margin-bottom: $line-height;
  }

  .clamp.overflowing &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 80px;
    height: $line-height;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }

  @mixin clamp-lines-height($num) {
    // Do some extra work to try to avoid rounding errors where you see 1 or 2 px of the following line.
    // This effectively cuts off the text bottom of the font's height on the
    // last line -- no extra space for the line height.  Then we use
    // margin-bottom to add the extra height at the end.
    height: $line-height * ($num - 1) + 1;
    margin-bottom: $line-height - 1;
  }

  @for $num from 1 through $max-lines {
    &.clamp-xs-#{$num} {
      @include clamp-lines-height($num);
    }
  }

  @each $breakpoint in $breakpoints_legacy {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);

    @media only screen and (min-width: $size) {
      @for $num from 1 through $max-lines {
        &.clamp-#{$name}-#{$num} {
          @include clamp-lines-height($num);
        }
      }
    }
  }

  .clamp.expanded & {
    height: auto;
  }
}

.clamp-list {
  li {
    display: none;
  }

  @for $num from 1 through $max-lines {
    &.clamp-xs-#{$num} {
      $selector: "li:nth-child(1)";
      @for $i from 2 through $num {
        $selector: "#{$selector}, li:nth-child(#{$i})";
      }
      #{$selector} {
        display: flex;
      }
    }
  }

  @each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);

    @media only screen and (min-width: $size) {
      @for $num from 1 through $max-lines {
        &.clamp-#{$name}-#{$num} {
          $selector: "li:nth-child(1)";
          @for $i from 2 through $num {
            $selector: "#{$selector}, li:nth-child(#{$i})";
          }
          #{$selector} {
            display: flex;
          }
        }
      }
    }
  }

  .clamp.expanded & li {
    display: flex;
  }
}

.clamp-expand, .clamp-collapse {
  display: none;
  color: $text-color;
  @include font-weight-bold;
}
.clamp.overflowing {
  .clamp-expand {
    display: inline;
  }

  &.expanded {
    .clamp-expand {
      display: none;
    }
    .clamp-collapse {
      display: inline;
    }
  }
}
