// Navigation

.nav-table {
  display: table;
  padding: 0;
  margin: 0;
  margin-bottom: 35px;
  width: 100%;
  li {
    position: relative;
    display: table-row;
    &:hover {
      background-color: rgba(0,0,0,.05);
      cursor: pointer;
    }
    .td {
      display: table-cell;
      vertical-align: middle;
      padding: 8px;
    }
    a {
      color: $charcoal;
      padding: 0;
      &:after {
        position: absolute;
        display: block;
        right: 30px;
        top: 50%;
        margin-top: -10px;
        font-family: 'amara';
        @extend .icon-forward:before;
        font-size: 17px;
        color: $teal;
      }
    }
  }
}
