/**
Variables
**/

// Set the number of columns you want to use on your layout.
$grid-columns: 12 !default;
// Set the gutter between columns.
$gutter-width: 2rem !default;
// Set a margin for the container sides.
$outer-margin: 2rem !default;
// Create or remove breakpoints for your project
// Syntax:
// name SIZErem,
//
// TODO: refactor code to use the $breakpoints variable
$breakpoints_legacy:
  sm map-get($breakpoints, tablet) $container-sm,
  md map-get($breakpoints, laptop) $container-md,
  lg map-get($breakpoints, desktop) $container-lg;

$flexboxgrid-max-width: 1200px !default;

$gutter-compensation: $gutter-width * .5 * -1;
$half-gutter-width: $gutter-width * .5;



/**
Mixins
**/

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual props
  flex-grow: 0;
  flex-shrink: 0;

  // we leave @include flex-basis(auto) out of common because  
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126

  padding-right: $half-gutter-width;
  padding-left: $half-gutter-width;
  &.collapse-start { padding-left: 0; }
  &.collapse-end   { padding-right: 0; }
  &.collapse-gutters   { padding-right: 0; padding-left: 0; }
}

/**
Grid
**/

.wrapper {
  box-sizing: border-box;
  max-width: $flexboxgrid-max-width;
  margin: 0 auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $outer-margin;
  padding-left: $outer-margin;
}

.row, .grid {
  box-sizing: border-box;
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $gutter-compensation;
  margin-left: $gutter-compensation;
  &.condensed {
    margin-right: 0;
    margin-left: 0;
    [class^='col-'] {
      padding-right: $half-gutter-width * 0.5;
      padding-left: $half-gutter-width * 0.5;
    }
  }
}

.grid-centered {
  justify-content: center;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

$name: xs;
.col-#{$name} {
  @include flexboxgrid-sass-col-common;
  flex-basis: auto;
}
@for $i from 1 through $grid-columns {
  .col-#{$name}-#{$i} {
    @include flexboxgrid-sass-col-common;
    flex-basis: 100% / $grid-columns * $i;
    max-width: 100% / $grid-columns * $i;
  }
}
@for $i from 1 through $grid-columns {
  .col-#{$name}-offset-#{$i} {
    @include flexboxgrid-sass-col-common;
    margin-left: 100% / $grid-columns * $i;
  }
}
.col-#{$name} {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  &.collapse-start { padding-left: 0; }
  &.collapse-end   { padding-right: 0; }
}
.start-#{$name} {
  justify-content: flex-start;
  text-align: start;
}

.center-#{$name} {
  justify-content: center;
  text-align: center;
}

.end-#{$name} {
  justify-content: flex-end;
  text-align: end;
}

.top-#{$name} {
  align-items: flex-start;
}

.middle-#{$name} {
  align-items: center;
}

.bottom-#{$name} {
  align-items: flex-end;
}

.around-#{$name} {
  justify-content: space-around;
}

.between-#{$name} {
  justify-content: space-between;
}

.first-#{$name} {
  order: -1;
}

.last-#{$name} {
  order: 1;
}


@each $breakpoint in $breakpoints_legacy {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  $container: nth($breakpoint, 3);
  @media only screen and (min-width: $size) {

    .col-#{$name} {
      @include flexboxgrid-sass-col-common;
      flex-basis: auto;
    }
    @for $i from 1 through $grid-columns {
      .col-#{$name}-#{$i} {
        @include flexboxgrid-sass-col-common;
        flex-basis: 100% / $grid-columns * $i;
        max-width: 100% / $grid-columns * $i;
      }
    }
    @for $i from 1 through $grid-columns {
      .col-#{$name}-offset-#{$i} {
        @include flexboxgrid-sass-col-common;
        margin-left: 100% / $grid-columns * $i
      }
    }
    .col-#{$name} {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }
    .start-#{$name} {
      justify-content: flex-start;
      text-align: start;
    }

    .center-#{$name} {
      justify-content: center;
      text-align: center;
    }

    .end-#{$name} {
      justify-content: flex-end;
      text-align: end;
    }

    .top-#{$name} {
      align-items: flex-start;
    }

    .middle-#{$name} {
      align-items: center;
    }

    .bottom-#{$name} {
      align-items: flex-end;
    }

    .around-#{$name} {
      justify-content: space-around;
    }

    .between-#{$name} {
      justify-content: space-between;
    }

    .first-#{$name} {
      order: -1;
    }

    .last-#{$name} {
      order: 1;
    }
  }
}


.container-md {
  max-width: nth(nth($breakpoints_legacy, 2), 3);
}

.container-sm {
  max-width: nth(nth($breakpoints_legacy, 1), 3);
}
