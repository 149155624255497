// Generic version of the application/views/dashboard code
//
// TODO merge that code with this and remove it

.dashboard-section {
  max-width: none;
  &:nth-of-type(even) {
    background: $snow;
    border-top: 1px solid light($gray);
    border-bottom: 1px solid light($gray);
    .dashboard-contrasting {
      background-color: $white
    }
  }
}

.dashboard-contrasting {
  background-color: $snow;
}

.dashboard-moreLink {
  text-align: right;
  padding: 8px;
}

