// Action Bar
//
// Example:
// <div id="styleGuide-actionBar" class="actionBar">
//   <button class="button cta" data-toggle="modal" data-target="#modal_id">Move</button>
//   <button class="button cta">Mark complete</button>
//   <button class="button cta">Manage assignments</button>
//   <button class="button cta-reverse">Delete</button>
//   <button class="button inline">Deselect</button>
// </div>
// <button class="actionBar-open button cta" data-target="#styleGuide-actionBar">Open Action Bar</button>
//
// Styleguide actionBar

.actionBar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: -100px;
  left: 0;
  width: 100%;
  height: 80px;

  background-color: $snow;
  color: $text-color;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.3);
  z-index: $z-index-action-bar;

  @include respond-to(phone) {
    height: 180px;
    bottom: -200px;
  }

  @include respond-to(tablet) {
    height: 120px;
    bottom: -140px;
  }

  @include transition(0.5s);
  &.open {
    bottom: 0;
  }
  .button {
    margin: 0 10px;
    @include respond-to(phone, tablet) {
      margin: 5px 10px;
    }
    &.actionBar-deselect {
      &:before{
        content: url("../img/btn-deselect.svg");
      }
      &:hover {
        color: $Text;
      }
    }
    &.btn-download {
      &:before{
        content: url("../img/btn-download.svg");
      }
      &:hover {
        color: $Text;
      }
    }
    &.btn-upload {
        &:before{
          content: url("../img/btn-upload.svg");
        }
        &:hover {
          color: $Text;
        }
    }
    &.btn-order {

    }

    &.btn-request {
      &:before{
        content: url("../img/btn-request.svg");
      }
      &:hover {
       color: $Text;
      }
    }
    &.btn-remove-member, &.btn-delete {
      &:before{
        content: url("../img/btn-remove-member.svg");
      }
      &:hover {
       color: $Text;
      }
    }
    &.btn-change-role {
      &:before{
        content: url("../img/btn-change-role.svg");
      }
      &:hover {
       color: $Text;
      }
    }
    &.btn-set-teams {
      &:before{
        content: url("../img/btn-change-role.svg");
      }
      &:hover {
       color: $Text;
      }
    }
    &.Actionicon-edit, &.Actionicon-move, &.Actionicon-remove {
      background-color: transparent;
      padding: 1em;
      color: $Text;
      border-radius: 3.6rem;
      width: 5.6rem;
      @include transition;
      span {
        display: none;
      }
      &:hover {
        color: $Text;
        background-color: #fff;
      }
    }

    &.Actionicon-edit {

    }
    &.Actionicon-move {
      &:before{
        content: url("../img/Actionicon-move.svg");
      }
    }
    &.Actionicon-remove {

    }
  }
}
