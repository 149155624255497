.splitButton {
  display: flex;
  color: $Primary;
  font-size:16px;
  padding: 7px 10px;
  margin: 0;
  border: 2px solid $Primary;
  background-color: #fff;
  border-radius:6px;
  align-items: stretch;
  @include transition;
  &:hover, &:active, &:focus, &:focus-visible {
    outline: none !important;
    background-color: $Primary;
    color: #fff;
    > a, > button {
      color: #fff;
    }
  }
  &[disabled], &:disabled {
    color: dark($gray);
  }
  &.large {
    width: 300px;
  }
  &.cta-reverse {
    color: $Text;
    background-color: $white;
    border: 2px solid $Grey700;
    &:hover {
      background-color: $Grey500;
      color: $Text;
      border-color: $Grey700;
      > a, > button {
        color: $Grey900;
      }
    }
  }
  &.delete {
    color: $amaranth;
    background-color: transparent;
    border-color: $amaranth;
  }

  &.disabled {
    color: $disabled-gray-fg;
    border-color: $disabled-gray-bg;
    background-color: $disabled-gray-bg;
  }
}

.splitButton-main {
  @include no-underline;
  flex-grow: 1;
  color: inherit;
  font-weight: $font-weight-normal;
  text-align: center;
  line-height: 20px;
  padding: 0 16px;
  &:hover, &.hover, &:focus {
    color: $Primary;
    background-color: none;
  }
  &.disabled {
    cursor: default;
    color: inherit;
    &:hover, &.hover, &:focus {
      color: inherit;
      background-color: none;
    }
  }
}

.splitButton-secondary {
  display: block;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: all .4s ease-in-out;
  width: 30px;
}

// TODO: This is the old code, but it's still used on the simple teams
// dashboard page.  Let's remove it when we convert that to using videoCard2

.split-button {
  margin-bottom: 0px !important;
  margin-right: 0px !important;
}

.split-button-container {
  display: inline-flex;
}

.split-button-container-full-width {
  display: flex;
}

.split-button-dropdown-toggle {
  margin-left: 2px !important;
  padding: 7px 0px;
  width: 40px !important;
}
