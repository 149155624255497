.subtitle-order-tab {
  .hero-order {
    text-align: center;
    margin: 3% 0 1% 0;
    font-size: 2.3em;
  }
  .card {
    border: 1px solid $Accent200;
    min-height: 502px;
    height:auto;
    overflow:hidden;
    background: #fff;
    border-radius: 12px;
    margin-top: 3%;
    padding: 40px 43px 35px 43px;
    box-shadow: 1.4px 4.5px 3.1px rgba(0, 0, 0, 0.016),
    11px 36px 25px rgba(0, 0, 0, 0.03);
    .fa-ul {
      margin-left:1.6em;
    }
    @media(max-width: $screen-md-max) {
      text-align: center;
      min-height: 570px;
      .fa-ul {
        margin-left:27%;
        width: 65%;
        display: block;
        li {
          .list-left {
            text-align: left;
          }
        }
      }
    }
    @media(max-width: $screen-sm-max) {
      min-height: auto;
      .fa-ul {
        margin-left:30%;
      }
    }
    h2, .order-subtitles-fieldset-text p {
      text-align: left;
      @media(max-width: $screen-md-max) {
        text-align: center;
      }
    }
    img {
      display: block;
      width: 100%;
      max-width: 230px;
      height: auto;
      margin-bottom: 2%;
      opacity: 0.7;
      @media(max-width: $screen-md-max) {
        margin: 0 auto 2% auto;
      }
    }
    p {
      margin: 2% auto;
    }
  }
}

div.modal-content > aside > .asr-intro{
  margin-top: 5px;
  margin-bottom: 20px;
  background-image: none;
  background-color: $Info100;
  border-color: $Info500;
  color: $Text;
  a{
    color: $Text;
  }
  .close{
    color:$Info500;
  }
}


.asr-intro {
  img {
    width: 38px;
    float: left;
    margin-right: 1%;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: #AD93A3;
    margin: 0;
    padding-top: 8px;
  }
}
.asr-credits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  @include border-radius-6;
  border: 2px solid $Accent500;
  background: #fff;
  padding: 2%;
  &.bottom-display {
    display: inline-block;
    float: none;
    margin-right: -8px;
    margin-bottom: 1%;
  }
  p {
    color: $Text;
    margin: 0;
    strong {
      margin-left: 1%;
    }
  }

  &.video-display, &.dashboard-display {
    padding: 7px;
    @include border-radius-8;
    height: 45px;
    border: 2px solid $Accent500;
    background: #fff;
    @media(max-width: $screen-sm-min) {
      flex-direction: column;
      height: auto;
      p {
        width: auto;
        flex-direction: column;
      }
      a {
        margin-top: 3px;
      }
    }
    &.no-free-mn {
        p {
          width: 353px;
          strong {
            margin-left: 3px;
          }
          @media(max-width: $screen-sm-min) {
            text-align:center;
          }
        }
        @media(max-width: $screen-sm-min) {
          flex-direction: column;
          height: auto;
          a {
            margin-top: 3px;
          }
        }

    }
    p {
      display: flex;
      justify-content: flex-start;
      width: auto;
      min-width: 335px;
      font-size: 15px;
      align-items: center;
      margin-left: 1%;
    }
    span {
      float: left;
    }
    a {
      margin-left: 1%;
      margin-right: 15px;
    }
  }
  &.video-display, &.dashboard-display {
    .column-display {
      margin-left: 1%;
      p {
        width: 343px;
      }
      a {
        margin-left: 0;
        margin-right: 15px;
      }
      .text-card-standard {
        font-size: 13px;
        line-height: 17px;
      }
    }
    .column-display-link {
      margin-right: 5px;
    }
  }

  &.zero-state-display {
    display: block;
    float: none;
    width: 400px;
    height: auto;
    overflow: hidden;
    min-height: 70px;
    margin: 4% auto;
    background: $Accent100;
    &.no-free-mn-height {
      height: 80px;
    }
    .illustr-and-text-block {
      display: block;
      width: 100%;
      margin: 0 auto;
      .illustr-card {
        width: 15%;
        margin-top: 1%;
        float: left;
      }
      .text-card {
        width: 82%;
        display: block;
        float: right;
        text-align:left;
        p {
          line-height: 22px;
          font-weight: 400;
          &.title {
            font-size: 14px;
          }
        }
        .link-bold {
          @include font-weight-bold;
        }
        .text-card-standard {
          justify-content: start;
          @include font-weight-normal;
          strong {
            margin-left: 1%;
          }
        }
      }
    }
    &:hover{
      opacity:0.7;
    }
  }
  @media only screen and (min-width:0px) and (max-width:670px){
    float: none;
    &.video-display, &.dashboard-display {
      // width: 98.2%;
      margin: 2% auto;
    }
  }

  @media only screen and (min-width:671px) and (max-width:$screen-md-min){
    float: none;
    &.video-display, &.dashboard-display {
      // width: 71.2%;
      margin: 2% auto;
      a {
        margin-left: 5px;
        margin-top: -3px;
      }
    }
    &.zero-state-display {
      min-width: 400px;
      margin: 4% auto;
    }
  }
  @media only screen and (min-width:$screen-md-min) and (max-width:$screen-lg-min){
    float: none;
    &.video-display {
      // width: 76%;
      margin: 0;
    }
    &.dashboard-display {
      // width: 55%;
      margin: 0;
    }
    &.zero-state-display {
      min-width: 400px;
      margin: 4% auto;
    }
  }
}

.video-listView-no-subs-asr-promo {
  text-decoration: none;
  display: inline-block;
  text-align:left;
  .listView-asr-illustr-card {
    float: left;
    padding:0;
    width: 60px;;
  }
  .listView-asr-text-card {
    padding:0;
    max-width: 400px;
  }
  p {
    font-size:14px;
    color: $Text;
    text-align:left;
    margin:0px;
  }
  .link-bold{
    @include font-weight-bold;
  }
  .description-link{
    font-size:12px;
    line-height:14px;
    @include font-weight-normal;

  }
}

.asr-free-min {
  padding: 7px;
  margin: 20px auto 0 auto;
  @include border-radius-8;
  border: 2px solid $Accent500;
  background: #fff;
  svg {
    margin-top: 20%;
  }
  &.bottom-display {
    display: inline-block;
    float: none;
    margin-right: -8px;
  }
  p {
    color: $Info700;
    margin: 0;
  }
  @media(max-width: $screen-md-min) {
    float: none;
  }
}

.asr-order-container {
  padding: 36px;

  h2, p {
    color: $charcoal;
  }
}

.asr-languages-inner-container {
  display: flex;
  flex-wrap: wrap;
}

.asr-language {
  min-width: 600px;
  max-width: 600px;
  margin: 20px;
  padding: 20px 30px 20px 30px;
  border-radius: 25px;

  table {
    width: 100%;
    height: calc(100% - 60px);

    tr {
      height: 32px;

      td.order-video-remove {
        width: 40px;
      }

      td.order-video-duration {
        width: 75px;
      }

      td.order-video-price {
        width: 75px;
        text-align: right;
      }

      td.order-video-last {
        border-top: 1px solid #D3D3D3;
      }
    }
  }
}
.orderPage-bottom {
  margin: 3% 0 1% 0;
}