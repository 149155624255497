.filterList {
  margin-bottom: 20px;
  font-size: $font-size-small;
  ul {
    padding-left: 10px;
    list-style-type: none;
    li {
      padding: 2px 0;
    }
  }
}
.filterList-heading {
  background: light($gray);
  padding: 10px;
  margin-top: 0;
  margin-bottom: 16px;
  color: $plum;
  @include font-weight-light;
}

.filterList-content {
  max-height: 200px;
  overflow: auto;
}


.filterList-select {
  .select2-selection {
    margin-left: 10px;
  }
}

.filters {
  font-size: $font-size-small;
}
