ul.comments {
  li {
    position: relative;
    padding: 10px 0;
    &:nth-of-type(odd) {
      background-color: $snow;
    }
    &:last-child {
      background-color: #fff;
    }
    .avatar {
      margin-left: 0;
    }
    .memberName {
      @include font-weight-bold;
    }
    .memberRole {}
    .commentBody {
      position: relative;
      padding-bottom: 30px;
      margin-bottom: 0;
      span {
        position: absolute;
        bottom: 0px;
        right: 10px;
      }
    }
    form {
      margin-left: 60px;
      textarea {
        display: block;
        width: 100%;
        height: auto;
        padding: 6px 12px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 1.42857143;
        color: $text-color;
        background-color: #fff;
        background-image: none;
        border: 1px solid light($gray);
        box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
      }
    }
  }
}
