.team-workspace-section {
  padding: 20px;
  font-family: lato, sans-serif;
}

.team-card-container {
    padding-bottom: 40px;
}

.team-card {
  @include respond-to(desktop) {
    width: 400px;
    height: 240px;
    margin-bottom: 1rem;
    overflow: hidden;
  }
  display: inline-block;
  vertical-align: top;
  width: 280px;
  height: 260px;
  @include box-shadow;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin: 10px 10px 10px 10px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  text-decoration: none;
  @include transition(0.3s);
  color: $Text;
  border: 2px solid $Grey200;

  .info {
    display: block;
  }
  &:hover {
    cursor:pointer;
    box-shadow:none;
    color: $teal;
    border: 2px solid $teal;
    text-decoration: none;
    .team-card-inner .team-card-header .team-card-name .team-name,.team-card-inner .team-card-footer .link.right a{
      color: $teal;
    }
  }
  a {
    color: $Text;
    text-decoration: none;
    font-weight:400;
    &:hover {
      color: $teal;
    }
  }
}

.team-card-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.team-card-header {
  display: flex;
  img {
    border-radius:6px;
  }
}

.team-card-name {
  margin-left: 5px;
  margin-top: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .team-name {
    margin: 0px;
  }
}

.team-card-footer {
  margin-top: 15px;

  > .link {
    display: inline-block;

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }
}
