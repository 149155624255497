.contentHeader {
  display: flex;
  align-items: center;
}

.contentHeader-title {
  font-size: 28px;
    line-height: 34px;
    color: $Accent;
    margin-bottom: 15px;
    font-weight:700;
}

.contentHeader-search, .contentHeader-searchBarNoAjax {
  // pointer-events: none;
  position: relative;
  padding-right: 8px;
  flex-grow: 1;
  margin-bottom: 3%;
  &:after {
    // pointer-events: all;
    @extend .searchbar:after;
    color: $plum;
  }
  &:hover::after {
    // cursor: pointer;
    opacity: 0.8;
  }
}

.contentHeader-spacer {
  padding-right: 8px;
  flex-grow: 1;
}

.contentHeader-menu {
  padding: 10px 8px;
  color: $Accent;
}

.contentHeader-searchBar, .contentHeader-searchBarNoAjax {
  border: none;
  line-height: 24px;
  padding-right: 32px;
  height: 45px;
  color: $white;
  border: 2px solid $Grey400;
  color: $charcoal;
  border-radius:6px;
  @include transition(0.5s);
  &:hover, &:active, &:focus, &:focus-visible {
    outline: none !important;
    border: 2px solid $Primary;
  }
  &::placeholder {
    color: $plum;
  }
  &::placeholder {
    color: $Grey600;
    font-size: 16px;
  }
}

.clearable {
  background: #fff url(../img/clear.svg)  no-repeat right -25px center;
  padding: 8px 76px 10px 4px; /* Use the same right padding (18) in jQ! */
  transition: background 0.4s;
}
.clearable.x  { background-position: right 45px center; } /* (jQ) Show icon */
.clearable.onX { cursor: pointer; } /* (jQ) hover cursor style */
.clearable::-ms-clear {display: none; width:0; height:0;} /* Remove IE default X */


.contentFooter {
  display: flex;
  padding: 12px 20px;
  font-size: $font-size-small;

  &-inline {
    > a {
      padding: 0 15px 0 0;
    }
  }
}

.contentFooter-paginatorCount {
  text-align: right;
  color: $gray;
  margin-right: 8px;
  flex-grow: 1;
}
.contentFooter-paginator {
  text-align: right;
  > li {
    display: inline-block;
  }
  margin: 0;
  padding: 0;
}

.contentFooter-paginator-pullright {
  text-align: right;
  > li {
    display: inline-block;
  }
  margin: 0;
  margin-left: auto;
  padding: 0;
}
.contentFooter-paginatorLink {
  display: inline-block;
  color: $gray;
  @include font-weight-normal;
  @include no-underline;
  padding: 0 4px;

  &.current {
    color: $charcoal;
  }

  &:hover {
    color: $white;
    background: $lime;
  }
}

.contentFooter-userLimit {
  display: flex;
  padding: 5px 0px;

  &-inline {
    display: flex;
    color: $gray;
    padding: 0 15px;

    > a {
      padding: 0 15px;
    }
  }
}
