.dashboardHeader {
  height: auto;
  min-height: 60px;
  border-bottom: none;

  &.tight-spacing {
    height: auto;
    padding-bottom: 0;
  }
  &.asr-promo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media(max-width: $screen-sm-min) {
      flex-direction: column;
    }
  }
}

/* -----------------------------------------
Dashboard Sections
----------------------------------------- */
#dashboard {
  .dashboardContrasting {
    background-color: #f8f8f8;
    padding: 10px;
  }
  .pageSection {
    max-width: none;
    &:nth-of-type(even) {
      background: #f8f8f8;
      border-top: 1px solid light($gray);
      border-bottom: 1px solid light($gray);
      .dashboardContrasting {
        background-color: #fff;
      }
    }
  }
  .zero-state {
    border: 1px solid light($gray);
    text-align: center;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    &.welcome-workspace {
      background: #fff url(../img/welcome_workspace_bg.svg) no-repeat 50% 15%;
      min-height: 700px;
      padding-top: 2%;
      margin: 0 auto;
      h3.add-video {
        margin:5% 0;

      }
      .welcome-workspace-cta {
        .centered {
          margin:0 auto;
        }
        .welcome-workspace-cta-box {
          text-decoration: none;
          background: $Accent100;
          border:2px solid $Primary300;
          width:255px;
          padding: 9px 0px 0px 20px;
          border-radius: 8px;
          height: 70px;
          display: inline-block;
          margin-top: 7px;
          text-align:left;
          &:hover{
            opacity:0.7;
          }
          .illustr-card {
            padding:0;
            width:30%;
          }
          .text-card {
            padding:0;
            width:70%;
          }
          p {
            font-size:14px;
            color: $Text;
            text-align:left;
            margin:0px;
          }
          .link-bold{
            @include font-weight-bold;
          }
          .description-link{
            font-size:12px;
            line-height:14px;
            @include font-weight-normal;

          }
        }
      }
    }
  }

  .topLanguages {
    height: 275px;
    overflow-y: auto;
    overflow-x: hidden;
    li {
      position: relative;
      padding-left: 10px;
      padding-right: 10px;
      .topLanguages-count {
        float: right;
        color: $teal;
      }
    }
  }
}

#big_picture {
  .ct-chart {
    width: 70%;
    float: left;
    height: 350px;
  }

  .stat {
    width: 20%;
    float: left;
    height: 350px;
    font-size: 20px;
    text-align: center;
    display: table;
    p {
      display: table-cell;
      vertical-align: middle;
    }
    span {
      font-size: 50px;
    }
  }
}

#newest_members {
  .searchbar {
    margin-bottom: 15px;
  }
  .users {
    height: 220px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    li {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: nowrap;
      &:nth-child(odd) {
        border-right: 1px solid light($gray);
        span {
          &:last-child {
            margin: auto;
            margin-right: 5px;
          }
        }
      }
      &:nth-child(even) {
        span {
          &:last-child {
            margin: auto;
            margin-right: 5px;
          }
          &:first-child {
            margin: 0;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

/* -----------------------------------------
 Member Lists on Dashboard
 TODO - refactor
----------------------------------------- */

.users {
  height: 275px;
  padding: 5px;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
}

// Media Queries
@media(max-width: $screen-sm-max) {
  #dashboard {
    .zero-state{
      &.welcome-workspace {
        background: url(../img/welcome_workspace_bg.svg) no-repeat 50% 5%;
      }
    }
  }
}