.viewFilters-header {
  display: flex;
  gap: 10px;
  .tabs {
    margin: 0px;
  }
}
.filterBox {
  display: flex;
  // width: 180px;
  @media(max-width: $screen-md-min) {
    width: 100%;
  }
}
.video-page-filter-asr-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media(max-width: $screen-md-min) {
    width: 100%;
    flex-direction: column;
  }
}

// .filterBox-filters {
//   margin-top: 1%;
// }

.filterBox-filter {
  background: $snow;
  padding: 4px 10px 4px 10px;
  margin-right: 8px;
  margin-top: 4px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  @include border-radius-12;
}

.filter-removeFilter {
  margin-left: 8px;
  font-size: $font-size-tiny;
  line-height: 1;
  color: $gray;
  &:hover{
    color:#F15068;
  }
}

.filterBox-button {
  border: 2px solid $Grey400;
  color: $Text;
  max-height: 45px;
  @include font-weight-normal;
  padding: 9px;
  margin-right: 8px;
  border-radius: 6px;
  transition: all .5s ease-in-out;
  &:hover{
    color: $Primary;
    border: 2px solid $Primary;
  }
  @media(max-width: $screen-md-min) {
    width: 100%;
  }
}

.filterBox.hasFilters .filterBox-buttonText {
  display: none;
}

.filterBox-clearAllButton {
  display: none;
  color: $gray;
  text-decoration: underline;
  font-size: $font-size-small;
  padding: 4px;
  width: 142px;
  &:hover{
    color: $Primary;
  }

  .filterBox.hasFilters & {
    display: block;
  }
}

.filterBox-fields {
  display: none;
}


.filterBox-chooser {
  z-index: $z-index-menu;
  padding: 1em;
  background: $white;
  @include box-shadow;
  width: 300px;
  @include border-radius-12;

  &.wide {
    width: 800px;
  }
}

@include respond-to(phone) {
  .filterBox-chooser.wide {
    width: 300px;
  }
}

.filterBox-chooserField {
  padding: 16px 8px;

  .form-group {
    margin: 0;
  }
}

@include respond-to(tablet, 0) {
  .filterBox-chooser {
    width: 400px;
  }
}

@include respond-to(tablet) {
  .filterBox-chooser.wide {
    width: 600px;
  }
}

.filterBox-chooserActions {
  text-align: right;
  padding: 8px;
  display: flex;
}

.filterBox-chooserAction {
  @extend .button;
  display: block;
  margin: 0 auto;
  width: 30%;
  font-size: $font-size-small;
  padding: 5px;
}