.avatar {
  position: relative;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'amara' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 30px;
  border-radius:8px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Default color palette */
  color: $charcoal;
  background-color: #cccccc;

  // &:before {
  //   content: "\e90e";
  // }

  &.in-progress {
    opacity: 0.6;
  }

  &.avatar-alert {
    &:after {
      font-family: 'amara' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      vertical-align: middle;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      @extend .icon-alert:before;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      color: rgba($amaranth, 0.8);
      font-size: 20px;
      text-align: center;
      line-height: 30px;
      background: rgba(255,255,255,0.5);
      background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%,rgba(255,255,255,0.94) 13%,rgba(255,255,255,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#80ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    }
  }

  // Sizes
  &.avatar-md {
    width: 50px;
    height: 50px;
    margin: 0 10px 0 0;
    font-size: 50px;
  }
  &.avatar-lg {
    width: 100px;
    height: 100px;
    margin: 0 10px 0 0;
    font-size: 60px;
  }
  &.avatar-xl {
    width: 110px;
    height: 110px;
    font-size: 110px;
  }

  // Child avatar image
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius:8px;
  }
}

