.checkoutTable {
  width: 100%;
  margin-bottom: 40px;

  > tbody > tr {
    > td, > th {
      text-align: left;
      vertical-align: top;
      border-bottom: 1px solid $snow;
      padding: 20px 0;
    }
  }
}
