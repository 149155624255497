#add-videos-dialog {
    .modal-title {
        padding: 5px 0px;
    }.modal-tabs {
        margin: 5px 0 10px 0;
    }
    .multiple {
        h4 {
            font-family: 'DM Sans Bold', Lato, "Lucida Grande", Tahoma, Sans-Serif;
            font-weight: 700;
            font-size: 14px;

        }
    }
}
#uploader-drop-zone {
    .modal-content {
        &.upload {
            padding-top: 3%;
        }
        .url-file-container {
            padding: 0 2.45em;
        }
        .url-upload, file-upload {
            label {
                display: none;
            }
        }
        .url-upload  {
            position: relative;
            @media only screen and (min-width: 768px) {
                border-right: 1px solid $Grey200;
            }
            .form-group {
                padding-top: 0.3em;
            }
            h4 {
                font-family: $first-level-headings-font-family;
                font-weight: 700;
                font-size: 14px;

            }
        }
        .file-upload {
            h4 {
                font-family: 'DM Sans Bold', Lato, "Lucida Grande", Tahoma, Sans-Serif;
                font-weight: 700;
                font-size: 14px;
            }

            .alert {
                margin: 12px 0;
                &.alert-error {
                    font-size: 0.9em;
                    background-color: $Warning100;
                    border-color: $Warning;
                    color: $Warning900;
                }
            }
            #add-video-dropzone {
                display: block;
                height: auto;
                // min-height: 25vh;
                // flex-direction: row;
                // align-items: center;
                // justify-content: center;
                margin: 0.6em 0;
                border: 2px solid $Grey400;
                border-radius: 6px;
                background-color: #fff;
                color: $Text;
                @include transition;
                &:hover, &:focus {
                    border: 2px solid $Primary;
                    > .dz-message-left-link {
                        opacity: 0.8;
                    }

                }
                .dz-preview {
                    border: 4px solid $Primary400;
                    border-radius: 6px;
                    // .dz-success-mark {
                    //     background: $Primary400
                    // }
                    .dz-details {
                        padding: 1em;
                        .dz-size {
                            margin-bottom: 0.3em;
                        }
                    }
                    &.dz-success {
                        .dz-success-mark {
                            top: 60%;
                            background: $Primary400;
                            animation: slide-in 3s cubic-bezier(0.770, 0.000, 0.175, 1.000) !important;
                            opacity: 1;
                        }
                      }
                    .dz-error-mark {
                        margin-top: 0.4em;
                        width: 54px;
                        height: 54px;
                        background: url(../img/video_upload_error.svg) no-repeat;
                        svg {
                            // override native dropzone icon that was too close to a removal action icon - added background to dz-error-mark
                            display: none;
                        }
                    }
                    .dz-progress {
                        background: #fff;
                        border-color: $Primary400;
                        .dz-upload {
                            background: $Primary400;
                        }
                    }

                }

                .dz-file-preview {
                    .dz-image {
                        border-radius: 6px;
                        background: #fff;
                        color: $Text;
                    }
                    .dz-remove {
                        font-size: 12px;
                        color: $Text;
                        cursor: pointer;
                        font-family: 'DM Sans Bold', Lato, "Lucida Grande", Tahoma, Sans-Serif;
                        font-weight: 700;
                        &:hover {
                            opacity: 0.8;
                        }
                        &:before {
                            content: "⊗ ";
                            font-size: 16px;
                        }
                    }

                }
                .dz-button {
                    opacity: 0;
                }
                .row-message {
                    position: relative;
                    flex: 0 0 100%;
                    display: flex;
                    flex-direction: row;
                    padding: 4.5vmin 3vmin 3vmin 3vmin;
                    cursor: pointer;
                    &:hover {
                        opacity: 0.8;
                        img {
                            &.illustration-anim {
                                animation:slide-top-right 1.5s ease-in-out both;
                                @keyframes slide-top-right{0%{transform:translateY(0) translateX(0)}50%{transform:translateY(-12px) translateX(30px)} 100%{transform:translateY(0) translateX(0)}}
                            }
                        }
                    }
                    img {
                        cursor: pointer;
                        &.illustration-bg {
                            z-index: 2;
                        }
                        &.illustration-anim {
                            animation-iteration-count: 1;
                            z-index: 3;
                            position: absolute;
                            left: 69px;
                            @include respond-to(phone) {
                                left: 12px;
                            }
                        }

                    }
                    .dz-message-left {
                        font-size: 0.85em;
                        margin: 1em 0;
                        cursor: pointer;
                        .dz-message-left-link {
                            font-family: $first-level-headings-font-family;
                            font-weight: bold;
                            color: $Primary;
                            text-decoration: underline;
                            cursor: pointer;
                            &:hover{
                                opacity: 0.8;
                            }
                        }
                    }
                }
                .dz-success {
                    border: 4px solid $Primary400;
                }
                .dz-error {
                    border: 4px solid $Warning;
                    border-radius: 6px;
                    color: $Warning900;
                    .dz-image {
                        border-radius: 6px;
                        color: $Text;
                    }
                    .dz-error-message {
                        top: -86px;
                        background: $Warning100;
                        color: $Text;
                        &:after{
                            top: 78px;
                            bottom: 0;
                            left: 64px;
                            width: 0;
                            height: 0;
                            border-left: 6px solid transparent;
                            border-right: 6px solid transparent;
                            border-bottom: none;
                            border-top: 6px solid $Warning100;
                        }

                    }
                }
                img {
                    display: block;
                    margin: 0 auto;
                }
            }
            .gdrive {
                text-decoration: none;
                display: block;
                width: 100%;
                background: #fff;
                margin: 1em auto 0 auto;
                border-radius: 6px;
                img {
                    margin-right: 10px;
                }
            }
            .form-group {
                label {
                    display: none;
                }
                #id_video_url, #select2-id_language-container {
                    display: block;
                    width: 60%;
                    background: transparent;
                    border: 1px solid $Grey900;
                }
                p.helpBlock {
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .video_file.has-advanced-upload {
                background-color: white;
                outline: 2px dashed black;
                outline-offset: -10px;
                }
                .video_file.has-advanced-upload .box__dragndrop {
                display: inline;
                }
                .video_file.is-dragover {
                background-color: grey;
                }
            .storage-info {
                font-family: $first-level-headings-font-family;
                font-weight: bold;
            }
            .dropzone {
                padding: 5px;
                .dz-message {
                    margin: 0;
                }
            }

        }
        .divider {
            position: relative;
            margin: 0 auto;
            height: 30px;
            width: 100%;
            .bar {
                height: 1px;
                position: absolute;
                background: $Grey900;
                &.bar-left {
                    width: 44%;
                    top: 51%;
                    left: 2%;
                }
                &.bar-right {
                    width: 44%;
                    top: 51%;
                    right: 2%;

                }
            }
            .upload-or {
                width: 35px;
                height: 35px;
                margin: 0 auto;
                text-align: center;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                border-radius: 10px;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                font-size: 1.5rem;
                line-height: 30px;
                color: $Grey900;
            }
        }
    }
    h2{
        margin: 0.7em 0 0.1em 0;
        text-align: left;
    }
    h3 {
        span {
            svg {
                margin-right: 0.3em;
                margin-top: -4px;
            }
        }

    }
    .language, .project {
        width: 100%;
    }
    .language {
        padding: 0 2.45em;
    }
    .project {
        margin-top: 5%;
        .form-group {
            padding: 0 2.45em;
        }
    }

    .modal-footer {
        text-align: right;
        padding-top: 1.5em;
    }
    #add-video-formsubmit {
        .spinner {
            float: left;
            margin-right: 7px;
            display: none;
        }
        &.disabled {
            > .spinner {
                display: block;
            }
        }
    }
}
.gdrive-container {
      width: 90vw;
      max-width: 1300px;
      @include border-radius-24;
      padding: 2% 5%;
      border: 6px solid $Grey300;
      @include respond-to(phone, tablet) {
        border: none;
      }
    .feature-highlight-star {
      position: relative;
      span {
        position: absolute;
        right: -27px;
        top: 12px;
      }
    }
    p {
      @include respond-to(tablet) {
        text-align: center;
      }
      @include respond-to(phone) {
        text-align: center;
      }
    }
    .gdrive-container-img {
      display: block;
      margin: 2% auto 2% auto;
      opacity: 0.7;
      -webkit-filter: grayscale(95%);
       -moz-filter: grayscale(95%);
         -o-filter: grayscale(95%);
        -ms-filter: grayscale(95%);
            filter: grayscale(95%);
      @include respond-to(phone) {
        margin: 0 auto 0 auto;
      }
      &.loggedin {
        opacity: 0.7;
      -webkit-filter: grayscale(0%);
       -moz-filter: grayscale(0%);
         -o-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
            filter: grayscale(0%);
      }
    }

    .gdrive-auth, .gdrive-loggedin {
        width: fit-content;
        display: block;
        img {
            margin-right: 10px;
        }
    }

    p.tag {
        text-transform:uppercase;
        letter-spacing: 0.10em;
        font-size:16px;
        line-height:26px;
      }
      h2{
        margin: 0.7em 0 0.1em 0;
        text-align: left;
    }
    h3 {
        span {
            svg {
                margin-right: 0.3em;
                margin-top: -4px;
            }
        }

    }
    .language, .project {
        width: 31vw;
        @include respond-to(phone, tablet) {
            width: 75vw;
            display: block;
          }
          &.large {
            width: 35vw;
            @include respond-to(desktop) {
                width: 31vw;
            }
          }
    }
    .language {
        // padding: 0 2.45em;
        @include respond-to(phone, tablet) {
            padding: 0;
          }
        ul.selected_videos_list {
            margin: 1em 2em;
            li.gdrive_video_item {
                display: flex;
                justify-content: space-between;
                position: relative;
                .gdrive_video_filename {
                    text-align: left;
                    width: auto;
                    min-width: 315px;
                    word-wrap: break-word;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @include respond-to(phone, tablet) {
                        min-width: 270px;
                      }
                }
                .gdrive_video_size {
                    min-width: 80px;
                    font-size: 12px;
                    line-height: 24px;
                }
                .gdrive_video_remove_icon {
                    text-decoration: underline;
                    font-size: 12px;
                    color: $Warning;
                    cursor: pointer;
                    &:hover {
                        opacity: 0.7;
                    }

                }
                &.error-oversized {
                    color: $Error;
                    .oversize_text {
                        position: absolute;
                        left: -90px;
                        font-size: 12px;
                        @include respond-to(phone, tablet) {
                            left: -30px;
                            top: -15px;
                            font-size: 10px;
                        }
                    }
                }
                .gdrive-video-too-large-icon {
                    padding-left: 1em;
                    padding-top: 0.2em;
                    position: absolute;
                    left: -45px;
                    @include respond-to(phone, tablet) {
                        left: -40px;
                    }
                }
            }

        }
    }
    .project {
        margin-top: 5%;
        .form-group {
            padding: 0 2.45em;
            @include respond-to(phone, tablet) {
                padding: 0;
              }
        }

    }

    .modal-footer {
        text-align: right;
        padding-top: 1.5em;
        height: auto;
        .size-warning {
            display: none;
            &.displayed {
                display: block;
                text-align: right;
                color: $Error;
            }
        }
        #add-video-formsubmit {
            .spinner {
                float: left;
                margin-right: 7px;
                display: none;
            }
            &.disabled {
                > .spinner {
                    display: block;
                }
            }
        }
    }
    #id_selected_text {
        margin: 1em 0;
    }
    .upload-quota-display {
        #id-quota-current-text {
            font-family: $first-level-headings-font-family;
            font-weight: bold;
        }
        #id-quota-overflow-amount {
            font-family: $first-level-headings-font-family;
            font-weight: bold;
        }
        .quota-error-text {
            color: $Error;
            font-size: 14px;
        }
        .quota-max-display {
            height: 5px;
            width: 100%;
            background-color: $Grey400;
            border-radius: 12px;
        }
        .quota-current-display {
            height: 5px;
            transform: translate(0px, 5px);
            width: 0%;
            background-color: $Primary;
            border-radius: 12px;
        }
        .quota-projected-display {
            height: 5px;
            transform: translate(0px, 10px);
            width: 0%;
            background-color: $Primary900;
            border-radius: 12px;
        }
    }
}
.s3_pending {
    width: fit-content;
    padding: 0.6em 1em;
    background: $Primary050;
    @include border-radius-12;
    border: 1px solid $Info400;
    svg {
        float: left;
        margin-right: 5px;
        margin-top: -2px;
    }
}

@keyframes slide-in {
    0% {
      opacity: 0;
      transform: translateY(40px);
    }
    30% {
      opacity: 1;
      transform: translateY(0px);
    }
}
