.paginator {
  text-align: center;
}

.paginator-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  > li {
    border: 1px solid $plum;
    border-left-width: 0;
    display: inline-block;

    &:first-child {
      border-left-width: 1px;
    }
  }
}

.paginator-link {
  display: inline-block;
  padding: 4px 12px;
  background-color: $plum;
  color: $white;
  @include no-underline;

  &.active {
    color: $plum;
    background-color: white;
    cursor: default;
  }
  &:hover:not(.active):not(.disabled) {
    color: lime;
  }
}

.paginator-objectCount {
  color: $gray;
}

.pagination {
  li {
    a {
      color: $text-color;
      padding: 5px 12px;
    }
    &.active {
      a, span {
        color: #fff;
        background-color: $teal;
        &:hover, &:focus {
          color: #fff;
          background-color: $teal;
        }
      }
    }
  }
}

