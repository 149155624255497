.listView {
  display: grid;
  margin: 0 0 40px;
  position: relative;
  &.fillFieldset {
    margin: -20px -20px 40px -20px;
  }
}

.listView > * {
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;


  &.expanded {
    background: $snow;
    border-top: 1px solid light($gray);
    margin-top: -1px; // Avoid making the cell move downward when it expands
  }
  &.selected {
    padding-left: 4px;
    border-left: 6px solid $lime;
  }
  &.hover {
    background: $snow;
  }
}

.listView.expandable > * {
  cursor: pointer;
  &.expanded {
    cursor: auto;
  }
}

.listView-header {
  background: $snow;
  &.listView-checkbox {
    &:after {
      display: none;
    }
  }
  &.listView-expand-span-header {
    display: block;
    @media only screen and (min-width:0px) and (max-width:755px){
      display: none;
    }
  }
}

.listView-secondary {
  color: $gray;
  display: none;
  margin-top: 4px;

  &.visible {
    display: block;
  }
}

.listView-secondaryRow {
  position: relative;
  color: $gray;
  display: none;
  grid-column: 1 / -1;
  padding-top: 20px;
  white-space: normal;
  &.expanded {
    border-top: 0;
    border-bottom: 1px solid light($gray);

  }

  &.visible {
    display: block;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $gray-dark;
  }
  .hide-gap-on-collapse {
    position: absolute;
  }
}

.listView-secondaryRowSpecial {
  color: $gray;
  display: none;
  //grid-column: 1 / -1;
  padding-top: 20px;
  white-space: normal;

  &.expanded {
    border-top: 0;
    border-bottom: 1px solid light($gray);
  }

  &.visible {
    display: block;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $gray-dark;
  }
}

.listView-secondaryInfo {
  margin-bottom: 16px;
}

.listView-expand-span {
  .row-caret {
    cursor: pointer;
    color: $Grey500;
    transition: all ease-in-out 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
  &.expanded {
    .row-caret {
      -moz-transform: rotate(90deg) translate(2px, 4px);
      -webkit-transform: rotate(90deg) translate(2px, 4px);
      -o-transform: rotate(90deg) translate(2px, 4px);
      -ms-transform: rotate(90deg) translate(2px, 4px);
      transform: rotate(90deg) translate(2px, 4px);
    }
  }
}

.listView-checkbox {
  position: relative;
  &.expanded {
    grid-row-end: span 2;
    border-bottom: 1px solid light($gray);
    background: $snow;
  }
}



.listView-actions {
  display: flex;
  align-items: start;
  justify-content: flex-end;
  overflow: visible;
}


.listView-action {
  position: relative;
  color: $Grey800;
  font-size: $font-size-normal;
  // Make actions easier to click on by:
  //    - Making sure they're square rather than thin
  //    - Extending their boundary into the padding of the row
  min-width: 40px;
  padding: 10px;
  margin: -10px -6px;
  border-radius: 50%;

  &:hover {
    color: $Grey900;
  }
  &:after {
    position: absolute;
    z-index: $z-index-behind;
    content: "";
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    border-radius: 50%;

    @include transition(0.2s, all, linear);
  }
  &:hover:after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $snow;
  }

  &.hover, &.selected {
    visibility: visible;
  }
}

// Setting overflow visible so tooltip can be displayed for status
.clientJobOrders-status {
  overflow: visible;
  svg {
    margin-bottom: -2%;
  }
}

@mixin listView-grid-template-columns($columns, $collapsed) {
  $items: ();

  @each $column in $columns {
    $size: 1fr;
    @if index($collapsed, $column) {
      $size: auto;
    }

    $items: append($items, $size);
  }

  grid-template-columns: $items;
}

// Mixin to define a listview
//
// Args:
//   $spec: Mapping of breakpoint names to lists of CSS classes that should be
//     visible at that breakpoint and above, in left-to-right order.
//   $collapsed: List of CSS classes of columns that we don't give extra
//     space to.  By default this is listView-actions and listView-checkbox.
//
// Rules:
//   - Every child of the listView element should have a CSS class based on the
//     column their in (either a standard listView class like listView-checkbox,
//     or else something specific to your particular element like videoUrls-url).
//   - Each of those classes should be in exactly one of the above lists
//   - The listView-secondary class is a special case, since it has special
//     rules about when its visible.
//
//  Failure to follow the rules will result in a crazy layout
//
@mixin listView($spec, $collapsed: null) {
  // Little bit of defensive programming
  @each $breakpoint, $classes in $spec {
    @each $class in $classes {
      @if str-index($class, 'listview') {
        @error('Invalid class: ' + $class + ': make sure to use "listView" instead of "listview"');
      }
      @if str-slice($class, 1, 1) != "." {
        @error('Invalid class: ' + $class + ': make sure you start with a period');
      }
    }
  }
  @if $collapsed == null {
    $collapsed: ();
  }
  $collapsed: join($collapsed, ('.listView-actions', '.listView-checkbox'));

  // Use a mobile-first approach and define the styles for $phone outside of any media query.
  $phone: map-get($spec, phone);
  @if $phone == null {
    @error("phone breakpoint must be present in listview()");
  }
  $spec: map-remove($spec, phone);
  $column-count: length($phone);

  @include listView-grid-template-columns($phone, $collapsed);

  @if index($phone, ".listView-checkbox") {
    .listView-secondaryRow {
      grid-column-start: 2;
    }
  }

  // Hide CSS classes not visible at the phone width
  @each $breakpoint, $classes in $spec {
    @each $class in $classes {
      @if index($phone, $class) == null {
        > #{$class} {
          display: none;
        }
      }
    }
  }

  // For each breakpoint, update the layout and show the new columns
  @each $breakpoint, $columns in $spec {
    @include respond-to($breakpoint, 0) {
      @include listView-grid-template-columns($columns, $collapsed);
      @each $class in $columns {
        > #{$class} {
          @if $class == ".listView-actions" {
            display: flex;
          } @else {
            display: block;
          }
        }
      }
      @if index($columns, ".listView-checkbox") {
        .listView-secondaryRow {
          grid-column-start: 2;
        }
      }
      $column-count: max($column-count, length($columns));
    }
  }

  & {
    // Use column-count to give this info to the JS code.  It's ignore since we're using display: grid.
    column-count: $column-count;
  }
}

// Styles for individual list views

.listView {
  &.styleguideExample {
    @include listView((
      phone: (".styleguideExample-title", ".styleguideExample-language"),
      tablet: (".listView-checkbox", ".styleguideExample-title", ".styleguideExample-language", ".listView-actions"),
    ));
  }

  &.memberDirectory {
    @include listView((
      phone: (".memberDirectory-name", ".memberDirectory-role"),
      laptop: (".memberDirectory-name", ".memberDirectory-role", ".memberDirectory-experience"),
      desktop: (".memberDirectory-name", ".memberDirectory-role", ".memberDirectory-joined", ".memberDirectory-experience"),
    ));
  }

  &.memberDirectory-admin {
    @include listView((
      phone: (".memberDirectory-name", ".memberDirectory-role"),
      tablet: (".listView-checkbox", ".memberDirectory-name", ".memberDirectory-role", ".listView-actions"),
      laptop: (".listView-checkbox", ".memberDirectory-name", ".memberDirectory-role", ".memberDirectory-experience", ".listView-actions"),
      desktop: (".listView-checkbox", ".memberDirectory-name", ".memberDirectory-role", ".memberDirectory-joined", ".memberDirectory-experience", ".listView-actions"),
    ));
  }

  &.projectList {
    @include listView((
      phone: (".projectList-title", ".projectList-description"),
      tablet: (".projectList-title", ".projectList-description", ".listView-actions"),
    ));
  }

  &.integrationList {
    @include listView((
      phone: (".integrationList-channel"),
      tablet: (".integrationList-channel", ".listView-actions"),
    ));
  }

  &.syncHistoryList {
    @include listView((
      phone: (".syncHistoryList-title", ".listView-actions"),
      tablet: (".syncHistoryList-title", ".syncHistoryList-language", ".syncHistoryList-date", ".listView-actions"),
      desktop: (".syncHistoryList-title", ".syncHistoryList-channel", ".syncHistoryList-platform", ".syncHistoryList-language", ".syncHistoryList-date", ".listView-actions"),
    ));
  }

  &.syncHistoryListYouTube {
    @include listView((
      phone: (".syncHistoryList-title", ".listView-actions"),
      tablet: (".syncHistoryList-title", ".syncHistoryList-language", ".syncHistoryList-date", ".listView-actions"),
    ), $collapsed: ('.syncHistoryList-language'));
  }

  &.videoUrls {
    @include listView((
      phone: (".videoUrls-url", ".listView-actions"),
      laptop: (".videoUrls-url", ".videoUrls-badges", ".listView-actions"),
    ));
  }

  &.assignmentHistory {
    @include listView((
      phone: ('.assignmentHistory-video', '.assignmentHistory-language'),
      tablet: ('.assignmentHistory-video', '.assignmentHistory-language', '.assignmentHistory-type'),
      laptop: ('.assignmentHistory-video', '.assignmentHistory-language', '.assignmentHistory-type', '.assignmentHistory-date'),
      desktop: ('.assignmentHistory-video', '.assignmentHistory-language', '.assignmentHistory-type', '.assignmentHistory-date', '.assignmentHistory-status'),
    ), $collapsed: ('.assignmentHistory-status'));
  }

  &.videoFeedsList {
    @include listView((
      phone: (".videoFeedsList-title"),
      tablet: (".videoFeedsList-title", ".listView-actions"),
    ));
  }

  &.clientJobOrders {
    @include listView((
      phone: (".clientJobOrders-name", ".clientJobOrders-orderType", ".clientJobOrders-orderDate"),
      tablet: (".clientJobOrders-name", ".clientJobOrders-orderType", ".clientJobOrders-orderDate", ".clientJobOrders-status"),
      laptop: (".clientJobOrders-name", ".clientJobOrders-orderType", ".clientJobOrders-orderDate", ".clientJobOrders-status"),
      desktop: (".clientJobOrders-name", ".clientJobOrders-orderType", ".clientJobOrders-orderDate", ".clientJobOrders-status"),
    ));
  }

  &.clientOrderSubtitles {
    @include listView((
      phone: ('.clientOrderSubtitles-video', '.clientOrderSubtitles-video-lang', '.clientOrderSubtitles-subtitle-lang', '.clientOrderSubtitles-type'),
      tablet: ('.clientOrderSubtitles-video', '.clientOrderSubtitles-video-lang', '.clientOrderSubtitles-subtitle-lang', '.clientOrderSubtitles-type', '.clientOrderSubtitles-duration', '.clientOrderSubtitles-status', '.clientOrderSubtitles-action'),
      laptop: ('.clientOrderSubtitles-video', '.clientOrderSubtitles-video-lang', '.clientOrderSubtitles-subtitle-lang', '.clientOrderSubtitles-type', '.clientOrderSubtitles-duration', '.clientOrderSubtitles-status', '.clientOrderSubtitles-action'),
      desktop: ('.clientOrderSubtitles-video', '.clientOrderSubtitles-video-lang', '.clientOrderSubtitles-subtitle-lang', '.clientOrderSubtitles-type', '.clientOrderSubtitles-duration', '.clientOrderSubtitles-status', '.clientOrderSubtitles-action'),
    ));
  }

  &.clientOrderSubtitlesPct {
    @include listView((
      phone: ('.clientOrderSubtitlesPct-video', '.clientOrderSubtitlesPct-video-lang', '.clientOrderSubtitlesPct-subtitle-lang', '.clientOrderSubtitlesPct-type'),
      tablet: ('.clientOrderSubtitlesPct-video', '.clientOrderSubtitlesPct-video-lang', '.clientOrderSubtitlesPct-subtitle-lang', '.clientOrderSubtitlesPct-type', '.clientOrderSubtitlesPct-duration', '.clientOrderSubtitlesPct-action'),
      laptop: ('.clientOrderSubtitlesPct-video', '.clientOrderSubtitlesPct-video-lang', '.clientOrderSubtitlesPct-subtitle-lang', '.clientOrderSubtitlesPct-type', '.clientOrderSubtitlesPct-duration', '.clientOrderSubtitlesPct-action'),
      desktop: ('.clientOrderSubtitlesPct-video', '.clientOrderSubtitlesPct-video-lang', '.clientOrderSubtitlesPct-subtitle-lang', '.clientOrderSubtitlesPct-type', '.clientOrderSubtitlesPct-duration', '.clientOrderSubtitlesPct-action'),
    ));
  }

  &.orderVideos {
    @include listView((
      phone: ('.orderVideos-video', '.orderVideos-language'),
      tablet: ('.orderVideos-video', '.orderVideos-language', '.listView-duration', '.listView-actions'),
    ), $collapsed: ('.listView-duration'));
  }

  &.addVideosToOrder {
    @include listView((
      phone: ('.listView-checkbox', '.orderVideos-video', '.orderVideos-language'),
      tablet: ('.listView-checkbox', '.orderVideos-video', '.orderVideos-language', '.listView-duration'),
    ), $collapsed: ('.listView-duration'));
  }

  &.manageVideos {
    @include listView((
      phone: ('.manageVideos-video', '.manageVideos-language'),
      tablet: ('.listView-checkbox', '.listView-expand-span', '.manageVideos-video', '.manageVideos-language', '.listView-duration'),
      laptop: ('.listView-checkbox', '.listView-expand-span', '.manageVideos-video', '.manageVideos-language', '.listView-duration', '.listView-actions'),
    ), $collapsed: ('.listView-duration', '.listView-expand-span'));
  }

  &.manageVideos.no-checkbox {
    @include listView((
      phone: ('.manageVideos-video', '.manageVideos-language'),
      tablet: ('.listView-expand-span', '.manageVideos-video', '.manageVideos-language', '.manageVideos-duration'),
      laptop: ('.listView-expand-span', '.manageVideos-video', '.manageVideos-language', '.manageVideos-duration', '.listView-actions'),
    ), $collapsed: ('.manageVideos-duration', '.listView-expand-span'));
    .listView-secondaryRow {
      grid-column-start: 1;
    }
  }

  &.manageRequests {
    @include listView((
      phone: ('.manageRequests-video', '.manageRequests-language', '.manageRequests-warning'),
      tablet: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language', '.manageRequests-status', '.manageRequests-warning'),
      laptop: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language','.manageRequests-status', '.manageRequests-assigned', '.manageRequests-warning', '.listView-actions'),
    ), $collapsed: ('.manageRequests-warning'));
  }

  // for now, there can never be a list view with a combination of showing due dates and the work team for collabs
  &.manageRequests.withDueDates {
    @include listView((
      phone: ('.manageRequests-video', '.manageRequests-language', '.manageRequests-warning'),
      tablet: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language', '.manageRequests-status', '.manageRequests-warning'),
      laptop: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language','.manageRequests-status', '.manageRequests-due','.manageRequests-warning', '.listView-actions'),
      desktop: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language','.manageRequests-status', '.manageRequests-assigned', '.manageRequests-due','.manageRequests-warning', '.listView-actions'),
    ), $collapsed: ('.manageRequests-warning'));
  }

  &.manageRequests.jobOrderRequests {
    @include listView((
      phone: ('.manageRequests-video', '.manageRequests-language', '.manageRequests-warning'),
      tablet: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language', '.manageRequests-status', '.manageRequests-warning'),
      laptop: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language', '.manageRequests-workTeam', '.manageRequests-status', '.manageRequests-warning', '.listView-actions'),
    ), $collapsed: ('.manageRequests-warning'));
  }

  &.manageRequests.jobOrderWithGroupsRequests {
    @include listView((
      phone: ('.manageRequests-video', '.manageRequests-language', '.manageRequests-warning'),
      tablet: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language', '.manageRequests-status', '.manageRequests-warning'),
      laptop: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language', '.manageRequests-workTeam', '.manageRequests-group', '.manageRequests-status', '.manageRequests-warning', '.listView-actions'),
    ), $collapsed: ('.manageRequests-warning'));
  }

  &.manageRequests.pmRequestList {
    @include listView((
      phone: ('.manageRequests-video', '.manageRequests-language', '.manageRequests-warning'),
      tablet: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language', '.manageRequests-status', '.manageRequests-warning'),
      laptop: ('.listView-checkbox', '.manageRequests-video', '.manageRequests-language', '.manageRequests-clientTeam', '.manageRequests-workTeam', '.manageRequests-status', '.manageRequests-warning', '.listView-actions'),
    ), $collapsed: ('.manageRequests-warning'));
  }

  &.linguists {
    @include listView((
      phone: (".linguists-username", ".linguists-name", ".listView-actions"),
    ));
  }

  &.aodTeams {
    @include listView((
      phone: (".aodTeams-name", ".aodTeams-sourceLanguage", ".aodTeams-targetLanguage"),
    ));
  }

  &.jobOrders {
    @include listView((
      // Re-enable when we want to support due dates
      //phone: (".jobOrders-team", ".jobOrders-name", ".jobOrders-dueDate", ".jobOrders-progress", ".listView-actions"),
      phone: (".jobOrders-team", ".jobOrders-name", ".jobOrders-progress", ".listView-actions"),
    ));
  }

  &.jobOrders.closed {
    @include listView((
      // Re-enable when we want to support due dates
      phone: (".jobOrders-team", ".jobOrders-name", ".listView-actions"),
    ));
  }

  &.guidelines {
    @include listView((
      phone: (".guidelines-name", ".listView-actions"),
    ));
  }

  &.billingRecords {
    @include listView((
      phone: (".listView-checkbox", ".billingRecords-user", ".billingRecords-sourceTeam", ".billingRecords-video", ".billingRecords-language", ".billingRecords-type", ".billingRecords-details", ".billingRecords-amount", ".listView-actions")
    ));
  }

  &.billingRecordsLinguists {
    @include listView((
      phone: (".billingRecords-sourceTeam", ".billingRecords-video", ".billingRecords-language", ".billingRecords-type", ".billingRecords-details", ".billingRecords-amount")
    ));
  }

  &.redistributeCredits {
    @include listView((
      phone: (".redistributeCredits-role", ".redistributeCredits-user", ".redistributeCredits-billingMonth", ".redistributeCredits-credit", ".redistributeCredits-actions")
    ))
  }

  &.promoCodes {
    @include listView((
      phone: (".promoCodes-name", ".promoCodes-type", ".promoCodes-count", ".promoCodes-expires", ".listView-actions"),
    ));
  }


  // We have several classes for the video collaborations page, maybe we could try to consolidate these

  &.videoCollaborations {
    // All local requests, with no other teams involved
    @include listView((
      phone: (".listView-checkbox", ".videoCollaborations-language", ".videoCollaborations-status", ".videoCollaborations-assignments", ".listView-actions")
    ));
  }

  &.videoCollaborationsOtherTeams {
    // Your team is working on the request alongside other teams (but not the self-evaluation case).  This covers several cases:
    //   - Local requests with other teams evaluating
    //   - Incoming requests with other teams evaluating
    //   - Evaluations for other team's videos
    @include listView((
      phone: (".listView-checkbox", ".videoCollaborations-language", ".videoCollaborations-status", ".videoCollaborations-assignments", ".videoCollaborations-otherTeams", ".listView-actions")
    ));
  }

  &.videoCollaborationsOutgoing {
    // Outgoing requests, no self-evaluations
    @include listView((
      phone: (".listView-checkbox", ".videoCollaborations-language", ".videoCollaborations-status", ".videoCollaborations-otherTeams", ".listView-actions")
    ));
  }

  &.videoCollaborationsSelfEvaluation {
    // Outgoing requests, some self-evaluations
    @include listView((
      phone: (".listView-checkbox", ".videoCollaborations-language", ".videoCollaborations-selfEvaluation", ".videoCollaborations-status", ".videoCollaborations-assignments", ".videoCollaborations-otherTeams", ".listView-actions")
    ));
  }

  &.staffPageTeams {
    // Outgoing requests, some self-evaluations
    @include listView((
      phone: (".listView-checkbox", ".staffPageTeams-team", ".staffPageTeams-style", ".staffPageTeams-tags", ".staffPageTeams-membership", ".listView-actions")
    ), ".staffPageTeams-tags");
  }

  span#staff-team-members-count-disclaimer{
    font-size: 12px;
    font-style: italic;
  }

  &.staffPageTeamSyncing {
    // Outgoing requests, some self-evaluations
    @include listView((
      phone: (".listView-checkbox", ".staffPageTeamSyncing-video", ".staffPageTeamSyncing-videoType", ".staffPageTeamSyncing-language", ".staffPageTeamSyncing-lastVersion", ".staffPageTeamSyncing-status", ".listView-actions")
    ));
  }

  &.subtitleImportLog {
    // Outgoing requests, some self-evaluations
    @include listView((
      phone: (".subtitleImportLog-account", ".subtitleImportLog-video", ".subtitleImportLog-language", ".subtitleImportLog-time", ".subtitleImportLog-status"),
    ), (".subtitleImportLog-status",));
  }

  &.aodProgressTable {
    @include listView((
      phone: (".aodProgressTable-team", ".aodProgressTable-overdue", ".aodProgressTable-due-today", ".aodProgressTable-due-tomorrow", ".aodProgressTable-due-later", ".aodProgressTable-extra"),
    ));
  }

  &.staffPageUsers {
    @include listView((
      phone: (".listView-checkbox", ".staffPageUsers-user", ".staffPageUsers-name", ".listView-actions"),
    ));
  }

  &.subscriptions {
    @include listView((
      phone: (".subscriptions-team", ".subscriptions-subscription", ".subscriptions-type", ".subscriptions-languages", ".subscriptions-seats", ".subscriptions-modified"),
    ));
  }

  &.staffASROrders {
    @include listView((
      phone: (".staffASROrders-order", ".staffASROrders-team", ".staffASROrders-status", ".staffASROrders-paidmin", ".staffASROrders-freemin", ".staffASROrders-created"),
    ));
  }

  &.staffASROrderDetails {
    @include listView((
      phone: (".staffASROrderDetails-video", ".staffASROrderDetails-video-lang", ".staffASROrderDetails-subtitle-lang", ".staffASROrderDetails-duration", ".staffASROrderDetails-status"),
    ));
  }

  &.freeMinManage {
    @include listView((
      phone: (".listView-checkbox", ".freeMinManage-team", ".freeMinManage-style", ".freeMinManage-freemin", ".freeMinManage-minrem", ".listView-actions")
    ));
  }

  &.freeMinLogs {
    @include listView((
      phone: ( ".freeMinLogs-user", ".freeMinLogs-team", ".freeMinLogs-maxmin", ".freeMinLogs-freemin", ".freeMinLogs-time")
    ));
  }

  &.storageQuotaManage {
    @include listView((
      phone: (".listView-checkbox", ".storageQuotaManage-team", ".storageQuotaManage-style", ".storageQuotaManage-quota-size", ".storageQuotaManage-quota-usage", ".listView-actions")
    ));
  }

  &.storageQuotaLogs {
    @include listView((
      phone: ( ".storageQuotaLogs-user", ".storageQuotaLogs-team", ".storageQuotaLogs-quota-size", ".storageQuotaLogs-quota-usage", ".storageQuotaLogs-time")
    ));
  }

  &.deepgramScheduleQueue {
    @include listView((
      phone: ( ".listView-checkbox", ".deepgramScheduleQueue-created",
      ".deepgramScheduleQueue-video", ".deepgramScheduleQueue-language",
      ".deepgramScheduleQueue-providerrequestid")
    ));
  }
}

// Individual listview fields
.syncHistoryListYouTube {
  .syncHistoryList-date {
    text-align: right;
  }
}

.manageRequests-past-due-date {
  background: $amaranth;
  padding: 0 4px;
}

.aodProgressTable-overdue {
  color: $amaranth;
}

// Standard listview fields that are used in multiple places
.listView-duration {
  text-align: right;
}

.add-video-team-table-header{
  font-weight:bold;
  padding:6px 0;
}
// Fix for add-videos-team-videos-tab so empty state and 0 result search state are correctly displayed
.video-item {
  padding: 6px 0;
  .listView-checkbox {
    .checkbox{
      margin: 0 auto;
      width: 28px;
      padding: 0;
    }
  }
  .listView-duration {
    text-align:left;
  }
}
