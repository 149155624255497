.progressBar {
  position: relative;
  overflow: hidden;
  height: 24px;
  background-color: transparent;
  border: 2px solid #fff;
  @include border-radius-12;
}

.progressBar-progress {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  background-color: $charcoal;
  @include transition(0.6s);
}
.progressBar-percentage {
  float: right;
  color: inherit;
}

.progressBar-label {
  color: #fff;
  &.teal {
    color: $teal;
  }
  &.plum {
    color: $plum;
  }
}

// Colors
.progressBar.teal {
  border-color: $teal;
  .progressBar-progress {
    background-color: $teal;
  }
}
.progressBar.plum {
  border-color: $Success500;
  .progressBar-progress {
    background-color: $Success;
  }
}
.progressBar.multi {
  border-color: $charcoal;
  background-color: $white;
}
