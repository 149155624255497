.collabStepList {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;

  > li {
    display: block;
    padding-right: 32px;
    margin-bottom: 8px;
    &:last-of-type {
      padding-right: 0;
    }
    width: 300px;
    white-space: normal;
  }
}

.collabStepList-text {
  margin-left: 48px;
  font-size: $font-size-small;
}

.collabStepList-image {
  float: left;
  margin-right: 8px;
  width: 40px;
  height: 40px;
}

.collabStepList-actor {
  color: $gray-dark;
}

.collabStepList-warning {
  color: $amaranth;
  font-size: $font-size-tiny;
}

.collabVideoAssignments-filter {
  // Reposition this so it appears closer to the h2 element.
  margin-top: -16px;
  margin-bottom: 20px;
  margin-left: -16px;
}

.nav-table {
  .collabVideoAssignments-item {
    &:hover, &:focus, &:active {
      background-color: transparent;
    }
    a {
        &:hover {
          @include border-radius-12;
          background-color: $Accent300;
        }
      }
  }
}
