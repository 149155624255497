// CSS for the diffing page
//
// TODO: convert this to using our normal naming scheme

.subtitleDiff {
  width: 100%;
  border-bottom: 1px solid $snow;
  table-layout: fixed;

  > tbody > tr {
    border-bottom: 1px solid $snow;
  }
  > tbody > tr > td {
    padding: 12px 0;
  }
}

.subtitleDiff-timeCell {
  width: 200px;
  &.second {
    padding-left: 10px;
  }
}

.subtitleDiff-timeWrapper {
  display: inline-flex;
  font-size: $font-size-tiny;
  align-items: center;

  &.time-change {
    border: 1px dashed $amaranth;
    margin: -5px;
    padding: 4px;
  }
}

.subtitleDiff-time {
  color: $white;
  border-radius: 3px;
  padding: 0 8px;

  &.start {
    background: #576200;
  }
  &.end {
    background: #6f1f1c;
  }
}

.subtitleDiff-text {
  font-size: $font-size-small;

  &.text-change {
    color: $amaranth;
  }
}
